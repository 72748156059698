import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class CheckAuthGuardService {

  constructor(
    private router: Router
  ) { }

  canActivate(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {

    if (localStorage.getItem("vega")) {
      this.router.navigate(['/dashboard']);
      return false;

    }
    else {

      return true;
    }


  }
}
