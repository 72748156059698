import { NgModule } from '@angular/core';
import { CKEditorModule } from 'ng2-ckeditor';
import {
  NbActionsModule,
  NbButtonModule,
  NbCardModule,
  NbCheckboxModule,
  NbDatepickerModule, NbIconModule,
  NbInputModule,
  NbRadioModule,
  NbSelectModule,
  NbUserModule,
  NbTooltipModule
} from '@nebular/theme';
import { ThemeModule } from '../../@theme/theme.module';
import { ViewNetworkUserComponent } from './viewNetworkUser.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { FormsModule } from '@angular/forms';
// import { EditorsRoutingModule, routedComponents } from './editors-routing.module';

@NgModule({
  imports: [
    NbCardModule,
    ThemeModule,
    // EditorsRoutingModule,
    NbActionsModule,
    NbButtonModule,
    NbCheckboxModule,
    NbDatepickerModule, NbIconModule,
    NbInputModule,
    NbRadioModule,
    NbSelectModule,
    NbUserModule,
    CKEditorModule,
    NgxPaginationModule,
    NbTooltipModule, FormsModule
  ],
  declarations: [
    ViewNetworkUserComponent
    // ...routedComponents,
  ],
})
export class ViewNetworkUserModule { }
