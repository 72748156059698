import { NgModule } from '@angular/core';
import { CKEditorModule } from 'ng2-ckeditor';
import { FormsModule }   from '@angular/forms';
import {
    NbActionsModule,
    NbButtonModule,
    NbCardModule,
    NbCheckboxModule,
    NbDatepickerModule, NbIconModule,
    NbInputModule,
    NbRadioModule,
    NbSelectModule,
    NbUserModule,
  } from '@nebular/theme';
import { ThemeModule } from '../../@theme/theme.module';
import { AddGatewayComponent } from './addGateway.component';
import { CommonServiceService } from "../../common-service.service";

// import { EditorsRoutingModule, routedComponents } from './editors-routing.module';

@NgModule({
  imports: [
   
    NbCardModule,
    ThemeModule,
    // EditorsRoutingModule,
    NbActionsModule,
    NbButtonModule,
    NbCheckboxModule,
    NbDatepickerModule, NbIconModule,
    NbInputModule,
    NbRadioModule,
    NbSelectModule,
    NbUserModule,
    CKEditorModule,
    FormsModule
  ],
  declarations: [
    AddGatewayComponent
    // ...routedComponents,
  ],
  providers:[CommonServiceService]
})
export class AddGatewayModule { }
