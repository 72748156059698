import { Component, OnDestroy } from "@angular/core";
import { NbThemeService, NbToastrService } from "@nebular/theme";
import { takeWhile } from "rxjs/operators";
import { SolarData } from "../../@core/data/solar";
import { CommonServiceService } from "../../common-service.service";
import { URL } from "../../model/url";
import { ToasterConfig } from 'angular2-toaster';
import 'style-loader!angular2-toaster/toaster.css';
import { Router, ActivatedRoute } from "@angular/router";

interface CardSettings {
  title: string;
  iconClass: string;
  type: string;
}


@Component({
  selector: "ngx-gateway",
  styleUrls: ["./addGateway.component.scss"],
  templateUrl: "./addGateway.component.html"
})
export class AddGatewayComponent implements OnDestroy {
  config: ToasterConfig;
  private alive = true;
  roles = [];
  user = {
    firstname: "",
    lastname: "",
    email: "",
    companyname: '',
    address: "",
    roleid: ""
  };
  userData: any;

  constructor(
    private toastrService: NbToastrService,
    private themeService: NbThemeService,
    public cms: CommonServiceService,
    private router: Router,
    private solarService: SolarData
  ) {
    this.userData = this.cms.getuserData();
    console.log("userData", this.userData);

    this.getRoles();
  }

  ngOnDestroy() {
    this.alive = false;
  }

  getRoles() {
    this.cms.commonGetCall(URL.lookup).subscribe((resp: any) => {
      if (resp.Status == 200) {
        console.log("roles list", resp);
        if (resp.Status == 200) {

          resp.Data.forEach((x) => {
            if (x.lookupID > this.userData.Role) {
              this.roles.push(x)
            }
          })

          this.user.roleid = this.roles[0].lookupID;


        }
      } else {
      }
    });
  }

  changeRole(val) {
    // console.log("ththjaiofjpos", val);
    this.user.roleid = val;
  }
  error = [];

  AddUser() {

    this.error = [];
    var { error } = this;
    if (!this.user.firstname) {
      error[0] = "First Name is required "
    }
    if (!this.user.lastname) {
      error[1] = "Last Name is required "
    }

    if (!this.user.email) {
      error[2] = "Email is required "
    }
    if (!this.user.companyname) {
      error[3] = "Company Name is required "
    }
    if (!this.user.address) {
      error[4] = "Company Address is required "
    }
    if (this.error.length != 0) {
      return null;
    }

    let payload = {
      FNAME: this.user.firstname,
      LNAME: this.user.lastname,
      EMAIL: this.user.email,
      COMPANYNAME: this.user.companyname,
      COMPANYADDRESS: this.user.address,
      ROLE: this.user.roleid
    }
    this.cms.commonPostCall(URL.createUser, payload).subscribe((res: any) => {
      if (res.Status == 200) {
        this.toastrService.success(res.message, "Success")
        this.emptyFields()
      }
      else {
        this.toastrService.warning(res.message, "Error")
      }
    })

  }

  emptyFields() {

    this.user = {
      firstname: "",
      lastname: "",
      email: "",
      companyname: '',
      address: "",
      roleid: ""
    };
  }

    navigate(url)
    {
      this.router.navigateByUrl(url)
    }
  }
