import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbThemeService,NbToastrService } from '@nebular/theme';
import { takeWhile } from 'rxjs/operators';
import { SolarData } from '../../@core/data/solar';
import { URL } from '../../model/url';
import { CommonServiceService } from '../../common-service.service';
interface CardSettings {
  title: string;
  iconClass: string;
  type: string;
}

@Component({
  selector: 'ngx-notifications',
  styleUrls: ['./notifications.component.scss'],
  templateUrl: './notifications.component.html',
})
export class NotificetionsComponent implements OnDestroy, OnInit {

  private alive = true;
  getNotificationsData: any;

  constructor(private themeService: NbThemeService, public cms: CommonServiceService,
    private toastrService: NbToastrService,
    private solarService: SolarData) {

  }

  ngOnDestroy() {
    this.alive = false;
  }
  ngOnInit() {
    this.getNotifications();
  }
  getNotifications() {
    this.cms.commonGetCall(URL.getApprovalList)
      .subscribe((resp: any) => {
        if (resp.Status == 200) {
          console.log("notifications", resp);
          this.getNotificationsData=resp.Data;
        }
        else {

        }

        
      })

  }


  getApprovalStatus(obj) {
    let ApprovalID ;

    if(obj.ApprovalID)
    {
      ApprovalID = obj.ApprovalID;
    }
    else{
      ApprovalID = obj.data.ApprovalID;
    }
    let payload={
      "ApprovalID":ApprovalID,
      "Status": obj.id,
      "Comment": "test"
    }
    this.cms.commonPostCall(URL.approvalStatusUpdate,payload)
      .subscribe((resp: any) => {
        if (resp.Status == 200) {
          this.toastrService.success(resp.message,"Success")
          console.log("status", resp);
          this.getNotifications();
         // this.CoinBalance=resp.Data.balance
        }
        else {
          this.toastrService.warning(resp.message,"Error")
        }
      },err=>{
        console.log(err);
      })

  }
}
