import { Component, OnDestroy } from '@angular/core';
import { NbThemeService ,NbToastrService} from '@nebular/theme';
import { takeWhile } from 'rxjs/operators';
import { SolarData } from '../../@core/data/solar';
import { Router } from '@angular/router';
import { URL } from '../../model/url';
import { CommonServiceService } from '../../common-service.service';
import { ToasterConfig } from 'angular2-toaster';

@Component({
  selector: 'ngx-login',
  styleUrls: ['./changepassword.component.scss'],
  templateUrl: './changepassword.component.html',
})
export class ChangePasswordComponent implements OnDestroy {

  private alive = true;
  userDetails = {
    OldPassword: "",
    Password: "",
    ConfirmPassword: ""
  }


  constructor(private themeService: NbThemeService, public cms: CommonServiceService,
    private solarService: SolarData,
    private toastrService:NbToastrService,
    private router: Router) {
      // this.changePassword();
      
     }

  ngOnDestroy() {
    this.alive = false;
  }

  error = [];

  changePassword() {
    
      this.error = [];
      var { error } = this;
      if(!this.userDetails.OldPassword)
      {
        error[0] = "Current Password is required"
      }
      if(!this.userDetails.Password)
      {
        error[1] = "New Password is required"
      }
      if(!this.userDetails.ConfirmPassword)
      {
        error[2] = "Confirm Password is required"
      }

      if( this.error.length  != 0)
      {
        return null;
      }

    let url = URL.updatePassword;   
    let payload = {
      OldPassword: this.userDetails.OldPassword,
      Password:this.userDetails.Password,
      ConfirmPassword: this.userDetails.ConfirmPassword
    }
    if(this.userDetails.Password != this.userDetails.ConfirmPassword){
      // alert('Password and Confirm Password should match');
      this.toastrService.warning("Password and Confirm Password should match","Error")
    }
    else{
      this.cms.commonPostCall(url,payload).subscribe((res:any)=>{
        if(res.Status == 200){  
          this.toastrService.success(res.message,"Success")
          this.userDetails = {
            OldPassword: "",
            Password: "",
            ConfirmPassword: ""
          }
          // alert(res.message);        
        }
        else{
          this.toastrService.warning(res.message,"Error")
          // alert(res.message);
        }
       
      })
    }
    
  }

}
