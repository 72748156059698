import {Component, OnDestroy} from '@angular/core';
import { NbThemeService } from '@nebular/theme';
import { takeWhile } from 'rxjs/operators' ;
import { SolarData } from '../../@core/data/solar';
import {Router} from '@angular/router'

interface CardSettings {
  title: string;
  iconClass: string;
  type: string;
}

@Component({
  selector: 'ngx-pay-invoice',
  styleUrls: ['./logout.component.scss'],
  templateUrl: './logout.component.html',
})
export class LogoutComponent implements OnDestroy {

    private alive = true;

  constructor(private themeService: NbThemeService,
    private route:Router,
    private solarService: SolarData) {
      var self = this;
      setTimeout(function(){
        self.logout()
      },1000)

  }

  ngOnDestroy() {
    this.alive = false;
  }
  logout(){
    localStorage.clear();
    this.route.navigate(['/']);
  }
  AccountDisabled(message)
  {

  }
}
