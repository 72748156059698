import { Component, OnInit } from '@angular/core';
import { NbThemeService,NbToastrService } from '@nebular/theme';
import { takeWhile } from 'rxjs/operators' ;
import { SolarData } from '../../@core/data/solar';
import { Router } from '@angular/router';
import { URL} from '../../model/url';
import { CommonServiceService } from '../../common-service.service';
import { ToasterConfig } from 'angular2-toaster';
import 'style-loader!angular2-toaster/toaster.css';


@Component({
  selector: 'ngx-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

  private alive = true;
  user={
    fullname : '',
    email : '',
    password : '',
    repeatepassword : '',
    terms : false
  }

  navigateHome()
  {
    
  }
  config: ToasterConfig;

constructor(private themeService: NbThemeService, public cms:CommonServiceService,
            private solarService: SolarData,
            private toastrService: NbToastrService,
            private router:Router) {


              // this.toastrService.('error', 'Args Title');

            }

ngOnDestroy() {
  this.alive = false;
}
login(){
  this.router.navigate(['/'])
  // let url = URL.login;
  // let payload = {
  //   EMAIL : this.user.email,
  //   PASSWORD : this.user.password
  // }
  // this.cms.simplePost(url , payload).subscribe(res=>{
  //   console.log("hte login res is",res);
  //   if(res.Status == 200){
  //     var encryptData = this.cms.encryptData(res.Data);
  //     localStorage.setItem("vega", encryptData);
  //     //let data = JSON.stringify(res.Data);
  //     //localStorage.setItem('LoginData', data);
  //     this.toastrService.success(res.message,"Success")
  //    this.router.navigate(['/dashboard'])
    // }
    // else{
    //   this.toastrService.warning(res.message,"Error")
    // }
   
   
  // })


}

register(){

}

newAccountRequest()
{
  // let key = btoa("ashokcse505@gmail.com");
  this.router.navigateByUrl('/grandadmin')
}

forgotPassword(){
  this.router.navigate(['/forgotpassword'])
}

signUp(){
  this.router.navigate(['/register'])
}

  ngOnInit() {
  }

}
