import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CKEditorModule } from 'ng2-ckeditor';
import {
    NbActionsModule,
    NbButtonModule,
    NbCardModule,
    NbCheckboxModule,
    NbDatepickerModule, NbIconModule,
    NbInputModule,
    NbRadioModule,
    NbSelectModule,
    NbUserModule,
  } from '@nebular/theme';
import { ThemeModule } from '../../@theme/theme.module';
import { MetricsComponent } from './metrics.component';
import {NgxPaginationModule} from 'ngx-pagination'; 
import { ChartsModule } from 'ng2-charts';
import { BalancechartComponent } from './balancechart/balancechart'

@NgModule({
  imports: [
    CommonModule,
    ChartsModule,
    NbCardModule,
    ThemeModule,
    // EditorsRoutingModule,
    NbActionsModule,
    NbButtonModule,
    NbCheckboxModule,
    NbDatepickerModule, NbIconModule,
    NbInputModule,
    NbRadioModule,
    NbSelectModule,
    NbUserModule,
    CKEditorModule,
    NgxPaginationModule
  ],
  declarations: [
    BalancechartComponent,
    MetricsComponent
    // ...routedComponents,
  ],
  exports: [
    BalancechartComponent,
    MetricsComponent
  ]
})
export class MetricsModule { }
