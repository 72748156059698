import { Component, OnInit } from '@angular/core';
import { CommonServiceService } from '../../common-service.service';
import { URL } from "../../model/url";
import { Router} from '@angular/router'



@Component({
  selector: 'ngx-my-wallet',
  templateUrl: './my-wallet.component.html',
  styleUrls: ['./my-wallet.component.scss']
})
export class MyWalletComponent implements OnInit {

  constructor(public cms: CommonServiceService, public router:Router) { }
  AllBalances = []
  ngOnInit() {
    this.getAllBalances()
  }
 
  getAllBalances(){
    this.cms.commonGetCall(URL.getAllBalance).subscribe(
      (x: any) => {
        console.log("all balancessss",x);
        this.AllBalances = x.Data
        this.AllBalances.forEach(y=>{
          this.cms.coinsList.forEach(z=>{
            if(y.coin == z.name)
            {
              y.icon = z.icon;
            }
          })
        })
       
      },
      (err) => {
        console.log(err);
      }
    );
  }

  precision(num, len) {
    if (num) {
      var num = num.toString();
      var s = num.split(".");

      if (s.length == 2) {
        if (s[1].length > len) {
          return parseFloat(s[0] + "." + s[1].slice(0, len));
        } else {
          return parseFloat(num);
        }
      } else {
        return parseFloat(num);
      }
    } else {
      return 0;
    }
  }

  navToTransfer(){
this.router.navigate(['/transfer'])
  }

}
