import {Component, OnDestroy} from '@angular/core';
import { NbThemeService } from '@nebular/theme';
import { takeWhile } from 'rxjs/operators' ;
import { SolarData } from '../../@core/data/solar';

interface CardSettings {
  title: string;
  iconClass: string;
  type: string;
}

@Component({
  selector: 'ngx-pay-invoice',
  styleUrls: ['./pay-invoice.component.scss'],
  templateUrl: './pay-invoice.component.html',
})
export class PayInvoiceComponent implements OnDestroy {

    private alive = true;

  constructor(private themeService: NbThemeService,
              private solarService: SolarData) {

  }

  ngOnDestroy() {
    this.alive = false;
  }
}
