import { NgModule } from '@angular/core';
import { CKEditorModule } from 'ng2-ckeditor';
import {
    NbActionsModule,
    NbButtonModule,
    NbCardModule,
    NbCheckboxModule,
    NbDatepickerModule, NbIconModule,
    NbInputModule,
    NbRadioModule,
    NbSelectModule,
    NbUserModule,
  } from '@nebular/theme';
import { ThemeModule } from '../../@theme/theme.module';
import { PayInvoiceComponent} from './pay-invoice.component';
// import { EditorsRoutingModule, routedComponents } from './editors-routing.module';

@NgModule({
  imports: [
    NbCardModule,
    ThemeModule,
    // EditorsRoutingModule,
    NbActionsModule,
    NbButtonModule,
    NbCheckboxModule,
    NbDatepickerModule, NbIconModule,
    NbInputModule,
    NbRadioModule,
    NbSelectModule,
    NbUserModule,
    CKEditorModule,
  ],
  declarations: [
    PayInvoiceComponent
    // ...routedComponents,
  ],
})
export class PayInvoiceModule { }
