import { NgModule } from "@angular/core";
import { NbMenuModule, NbCardModule, NbActionsModule, NbButtonModule, NbCheckboxModule, NbDatepickerModule, NbIconModule, NbInputModule, NbRadioModule, NbSelectModule, NbUserModule } from "@nebular/theme";

import { ThemeModule } from "../@theme/theme.module";
import { PagesComponent } from "./pages.component";
import { DashboardModule } from "./dashboard/dashboard.module";
import { ECommerceModule } from "./e-commerce/e-commerce.module";
import { PagesRoutingModule } from "./pages-routing.module";
import { MiscellaneousModule } from "./miscellaneous/miscellaneous.module";
import { CreateInvoiceModule } from "./create-invoice/create-invoice.module";
import { AddGatewayModule } from "./addGateway/addGateway.module";
import { AccessHistoryModule } from "./access-history/accessHistory.module";
import { NotificationsModule } from "./notifications/notifications.module";
import { TransactionsModule } from "./transactions/transactions.module";
import { TransferModule } from "./transfer/transfer.module";
import { ViewNetworkModule } from "./view-network/viewNetwork.module";
import { PayInvoiceModule } from "./pay-invoice/pay-invoice.module";
import { GenerateAddressModule } from "./generateAddress/generateAddress.module";
import { LoginModules } from "./login/login.module";
import { SignupModules } from "./signup/signup.module";
import { ContactModule } from "./contact/contact.module";
import { editprofileModule } from "./editprofile/editprofile.module";
import { ChangePasswordModule } from "./changepassword/changepassword.module";
import { ForgotpasswordModules } from "./forgotpassword/forgotpassword.module";
import { LogoutModule } from "./logout/logout.module";
import { ViewNetworkUserModule } from "./view-network-user/viewNetworkUser.module";
import { MetricsModule } from "./metrics/metrics.module";
import { ApprovalconfigModule } from "./approvalconfig/approvalconfig.module";
import { NgxPaginationModule } from "ngx-pagination";
import { MyWalletModule } from "./my-wallet/my-wallet.module";
import { SettingsComponent } from './settings/settings.component'
import { CKEditorModule } from 'ng2-ckeditor';
import { FormsModule } from '@angular/forms';






// import { MyWalletComponent } from './my-wallet/my-wallet.component';
// import { MetricsComponent } from './metrics/metrics.component';
// import { SignupComponent } from './signup/signup.component';
@NgModule({
  imports: [
    PagesRoutingModule,
    ThemeModule,
    NbMenuModule,
    DashboardModule,
    ECommerceModule,
    MiscellaneousModule,
    CreateInvoiceModule,
    AddGatewayModule,
    AccessHistoryModule,
    NotificationsModule,
    TransactionsModule,
    TransferModule,
    ViewNetworkModule,
    PayInvoiceModule,
    GenerateAddressModule,
    editprofileModule,
    LoginModules,
    MetricsModule,
    SignupModules,
    ContactModule,
    ChangePasswordModule,
    ForgotpasswordModules,
    LogoutModule,
    NgxPaginationModule,
    ViewNetworkUserModule,
    ApprovalconfigModule,
    MyWalletModule,NbCardModule,
    NbActionsModule,
    NbButtonModule,
    NbCheckboxModule,
    NbDatepickerModule, NbIconModule,
    NbInputModule,
    NbRadioModule,
    NbSelectModule,
    NbUserModule,
    CKEditorModule,
    NgxPaginationModule,
    NbCheckboxModule,
    FormsModule


  ],
  declarations: [
    PagesComponent,
    SettingsComponent
    //TwofactorComponent,
    // MyWalletComponent,

    // MetricsComponent,
    // SignupComponent,
  ],
})
export class PagesModule {}
