import { NgModule } from '@angular/core';
import { CKEditorModule } from 'ng2-ckeditor';
import { FormsModule }   from '@angular/forms';
import {
    NbActionsModule,
    NbCardModule,
    NbCheckboxModule,
    NbDatepickerModule, NbIconModule,
    NbInputModule,
    NbRadioModule,
    NbSelectModule,
    NbUserModule,
    NbSidebarModule, NbLayoutModule, NbButtonModule 
    
  } from '@nebular/theme';
import { ThemeModule } from '../../@theme/theme.module';
import { LoginComponent } from './login.component';

@NgModule({
  imports: [
   NbLayoutModule,
    NbSidebarModule, // NbSidebarModule.forRoot(), //if this is your app.module
    NbButtonModule,
    NbCardModule,
    ThemeModule,
    // EditorsRoutingModule,
    NbActionsModule,
    NbButtonModule,
    NbCheckboxModule,
    NbDatepickerModule, NbIconModule,
    NbInputModule,
    NbRadioModule,
    NbSelectModule,
    NbUserModule,
    CKEditorModule,
     FormsModule
  ],
  declarations: [
    LoginComponent
    // ...routedComponents,
  ],
})
export class LoginModules { }
