import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbThemeService } from '@nebular/theme';
import { takeWhile } from 'rxjs/operators';
import { SolarData } from '../../@core/data/solar';
import { CommonServiceService } from "../../common-service.service";
import { URL } from "../../model/url";
import * as moment from 'moment';

interface CardSettings {
  title: string;
  iconClass: string;
  type: string;
}

@Component({
  selector: 'ngx-transactions',
  styleUrls: ['./transactions.component.scss'],
  templateUrl: './transactions.component.html',
})
export class TransactionsComponent implements OnInit {

  private alive = true;
  getList = []
  getAllTransactions = [];
  p: number = 1;
  searchlist: '';

  itemsPerPage: any;
  currentPage: any;
  coinid: any
  role: any;
  userData: any;

  config_Pagination = {
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: 0
  };

  constructor(private themeService: NbThemeService,
    public cms: CommonServiceService,
    private solarService: SolarData) {

    this.userData = this.cms.getuserData()
  }




  coinName = "";
  ngOnDestroy() {
    this.alive = false;
  }

  ngOnInit() {

    this.cms.getSelectCoin()
      .subscribe(data => {
        this.coinName = data.coinName;
        this.coinid = data.coinId;
        console.log("Coin ID", this.coinid);
        //  this.getTransactionsByCoinId(data);
        this.refresh();
      });
    // this.getTransactionsByCoinId({coinId:this.cms.getSelectCoinId});
    var coin = localStorage.getItem("coin");
    if (coin) {
      console.log("if coin issssss", coin);

      let data = JSON.parse(coin);
      this.coinName = data.coinName;
      this.coinid = data.coinId;
      this.refresh();

    }

    // console.log("coincoincoin",coin)

    // this.getCoinsList()
    // this.getTransactionsHystory()
  }




  getCoinsList() {

    this.cms.commonGetCall(URL.getcoinlist)
      .subscribe((resp: any) => {
        if (resp.Status == 200) {
          console.log("all coins list   .....", resp);
          let data = resp.Data;
          this.getcoinIds(data);
        }

      })
  }

  getcoinIds(data) {
    console.log("userdata type is", this.cms.getuserData());
    this.role = this.cms.getuserData().Role
    if (this.role == 6) {

      data.forEach(x => {
        this.getTransactionsByCoinId(x)
      })
    }
    else {
      data.forEach(x => {

        this.getTransactions(x);
      })
    }

  }

  //role == 6

  getTransactionsByCoinId = async (data) => {
    this.getAllTransactions = [];
    await this.cms.commonGetCall(URL.getTransaction + "/" + data.coinId).subscribe((resp: any) => {
      if (resp.Status == 200) {
        this.getAllTransactions = [];
        if (resp.Data.length != 0) {
          resp.Data.forEach((x: any) => {
            x.CoinName = data.coinName
            let date = (x.timereceived)* 1000
            x.timereceived = moment(date).format("DD/MM/YYYY HH:mm:a");
            this.getAllTransactions.push(x)


            this.sorting()

          })
          console.log("coinlistt issss", this.getAllTransactions);
        }

      }
      else {

      }
    })
  }





  refresh() {
    // this.getAllTransactions = []
    //     // this.getCoinsList()
    //     this.getTransactionsHystory();
    this.getTransactionsByCoinId({ coinName: this.coinName, coinId: this.coinid });
  }

  searchChanged(e) {
    console.log("search", e);
    if (e) {
      this.p = 1;
    }

  }

  getTransactionsHystory() {
    this.cms.commonGetCall(URL.getChildAllTransactionsHistory).subscribe((resp: any) => {
      console.log("getTransactionsHystory", resp);



      if (resp.Status == 200) {

        this.getAllTransactions = resp.Data

      }
      else {

      }
    })
  }










  getTransactions = async (data) => {
    await this.cms.commonGetCall(URL.getTransactionHistoryTotal + "/" + data.coinId).subscribe((resp: any) => {
      if (resp.Status == 200) {
        if (resp.Data.length != 0) {
          resp.Data.forEach((x: any) => {
            x.CoinName = data.coinName

            this.getAllTransactions.push(x)
            this.sorting()

          })
          console.log("coinlistt issss", this.getAllTransactions);
        }

      }
      else {

      }
    })

  }

  sorting() {
    this.getAllTransactions.sort(function (a, b) {

      return b.timereceived - a.timereceived;
      // return <any>new Date(b.time) - <any>new Date(a.time);
    });
  }

  download(type) {
    let user = this.cms.getuserData();
    console.log(user)
    let link = document.createElement("a");
    // link.download = "filename";
    // let url ='/cryptotax/api/'
    link.href = URL.getTransactionDownload + this.coinid + "/" + user.CmID + "?type=" + type;
    link.click();
    // this.cms.commonGetCall(URL.getTransactionDownload+this.coinid).subscribe((resp: any) => {
    //   console.log("resp-resp",resp)

    // })

  }


  openTransction(id, type, coinname) {
    //console.log("openTransction:",id,type)

    // this.cms.navigateTransction(id,type,this.coinName)

    this.cms.navigateTransction(id, type, coinname)

  }
}
