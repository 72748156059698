import { Component } from '@angular/core';
import { CommonServiceService } from '../../../common-service.service';

@Component({
  selector: 'ngx-one-column-layout',
  styleUrls: ['./one-column.layout.scss'],
  template: `
    <nb-layout windowMode>
      <nb-layout-header fixed>
        <ngx-header></ngx-header>
      </nb-layout-header>

      <nb-sidebar class="menu-sidebar" tag="menu-sidebar" responsive>
        <ng-content select="nb-menu"></ng-content>
      </nb-sidebar>

      <nb-layout-column>
        <ng-content select="router-outlet"></ng-content>
      </nb-layout-column>

      <nb-layout-footer fixed>
      <!--  <a class="logo" href="#" >
      <img [src]="logo" class="vegapay" />
      </a>
      -->

      <p (click) = "openMainSite()" class="common-heading-color text-center">Copyright 2020 VegaWallet - All Rights Reserved</p>
      </nb-layout-footer>
    </nb-layout>
  `,
})
export class OneColumnLayoutComponent {
  userData: any;
  logo = '';
  constructor(
    public cms: CommonServiceService
  ) {


  }

  ngOnInit() {

    this.userData = this.cms.getuserData();
    if(this.userData.logo)
    {
      this.logo = this.userData.logo;
    }
    else
    {
      this.logo = "../../../assets/images/vegapay-logo.png";
    }
  }
  openMainSite()
  {
    window.open('https://www.vegawallet.com/', "_blank");
  }


}
