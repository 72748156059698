import { Component, OnDestroy } from '@angular/core';
import { NbThemeService, NbToastrService } from '@nebular/theme';
import { SolarData } from '../../@core/data/solar';
import { takeWhile } from "rxjs/operators";
import { CommonServiceService } from "../../common-service.service";
import { URL } from "../../model/url";
import { ToasterConfig } from 'angular2-toaster';
import 'style-loader!angular2-toaster/toaster.css';
import { Router, ActivatedRoute } from "@angular/router";


interface CardSettings {
  title: string;
  iconClass: string;
  type: string;
}

@Component({
  selector: 'ngx-viewnetwork',
  styleUrls: ['./viewNetwork.component.scss'],
  templateUrl: './viewNetwork.component.html',
})
export class ViewNetworkComponent implements OnDestroy {
  config: ToasterConfig;
  private alive = true;
  userData: any;
  RoleName: any

  config_Pagination = {
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: 0
  };
  userList = [];
  searchlist: '';

  constructor(
    private toastrService: NbToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private themeService: NbThemeService,
    public cms: CommonServiceService,
    private solarService: SolarData
  ) {
    this.userData = this.cms.getuserData();
    // this.RoleName = this.user
    // console.log("userData", this.userData);
    // this.getuserList();
    this.route.queryParams.subscribe(params => {

      if (params.page) {
        this.config_Pagination.currentPage = params.page;
        this.getuserList();
        // this.getuserData();
      }
      else {
        this.router.navigateByUrl('/view-network?page=1')
      }
    })

  }

  pageChanged(page) {
    this.router.navigateByUrl('/view-network?page=' + page)
    // alert(page)
    // this.config_Pagination.currentPage = page;
    // this.getuserList();
  }




  searchedgetList() {
    var payload = {
      CMID: this.userData.CmID,
      search: this.searchlist
    }
    let url = "";
    let page = this.config_Pagination.currentPage - 1;
    url = URL.getUserList + page;
    this.cms.commonPostCallNoLoader(url, payload).subscribe((res: any) => {
      console.log("Users", res);
      if (res.Status == 200) {

        this.userList = res.Data;
        if (res.Data.length != 0) {
          this.config_Pagination.totalItems = res.Data[0].Count;
        }
        // this.toastrService.success(res.message,"Success");

      }
      else {
        this.toastrService.warning(res.message, "Error")
      }

    });

  }



  getuserList() {
    var payload = {
      CMID: this.userData.CmID,
      search: this.searchlist
    }
    let url = "";
    let page = this.config_Pagination.currentPage - 1;
    url = URL.getUserList + page;
    this.cms.commonPostCall(url, payload).subscribe((res: any) => {
      console.log("Users", res);
      if (res.Status == 200) {

        this.userList = res.Data;
        if (res.Data.length != 0) {
          this.config_Pagination.totalItems = res.Data[0].Count;
        }
        // this.toastrService.success(res.message,"Success");

      }
      else {
        this.toastrService.warning(res.message, "Error")
      }

    });

  }

  searchListMethod(data) {
    if (data.length >= 2) {
      this.searchlist = data;
      this.searchedgetList()
    }
    if (data.length == 0) {
      this.searchlist = data;
      this.searchedgetList()
    }

  }

  // getuserData(){
  //   var payload = {
  //     CmID : this.userData.CmID
  //   }
  //   let url = "";
  //   // let page = this.config_Pagination.currentPage - 1;
  //   url = URL.getChildData
  //   this.cms.commonPostCall(url, payload).subscribe((res: any) => {

  //     if (res.Status == 200) {

  //        this.userList = res.Data;

  //        console.log("child data issssss...",this.userList);



  //     }
  //     else {
  //       this.toastrService.warning(res.message, "Error")
  //     }

  //   });

  // }



  viewUser(user) {
    let key = btoa(user.CmID);
    this.router.navigateByUrl('/viewuser?user=' + key)
    //  console.log(key)
  }

  userUpdateStatus(idUsr, value) {

    //console.log("Staet", user.IsActive);
    // let payload = {
    //   "CMID": user.CmID,
    //   "IsActive": user.IsActive
    // }
    let payload = {
      "CMID": idUsr,
      "IsActive": value
    }
    console.log("Payloooaddd", payload);
    var url = URL.CHILD_ACTIVE_INACTIVE;
    this.cms.commonPostCall(url, payload).subscribe((res: any) => {
      console.log("Users", res);
      if (res.Status == 200) {
        this.getuserList();
        this.toastrService.success(res.message, "Success");

      }
      else {
        this.toastrService.warning(res.message, "Error")
      }


    });
  }
  navigate() {
    this.router.navigateByUrl('/add-gateway')
  }

  ngOnDestroy() {
    this.alive = false;
  }
  toggle(ind, value) {
    console.log(value);
    this.userUpdateStatus(ind, value);
    // if (this.userList[ind].IsActive == true) {
    //   console.log("Active")

    // } else {
    //   console.log("Inactive")
    // }

  }

  resendLink(email) {
    let url = URL.RESEND_LINK;
    let payload = {

      "EMAIL": email

    }
    this.cms.resendVerifLink(url, payload).subscribe(data => {
      let result: any = data;
      console.log(result);
      if (result.Status == 200) {
        this.toastrService.success("A verification link has been sent to the selected user email", "Success");
      } else {
        this.toastrService.warning("Something wen wrong please try again later ", "Error")
      }

    })
  }
}
