import { Component, OnInit } from "@angular/core";
import { NbDialogRef } from "@nebular/theme";
import { NewsService } from "../../layout/news.service";
import { NewsitemService } from "../newsitem.service";

@Component({
  selector: "ngx-newsmodal",
  templateUrl: "./newsmodal.component.html",
  styleUrls: ["./newsmodal.component.scss"],
})
export class NewsmodalComponent implements OnInit {
  newsItem: any;
  constructor(
    public ref: NbDialogRef<NewsmodalComponent>,
    private news: NewsitemService
  ) {
    
  }

  ngOnInit() {
    this.newsItem = this.news.getNewsItem();
    console.log(this.newsItem);
  }
  cancel() {
    this.ref.close();
  }
}
