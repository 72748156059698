import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class NewsitemService {
  newsItem: any;
  constructor() {}
  setNewsItem(item) {
    console.log("My Item", item);
    this.newsItem = item;
  }

  getNewsItem() {
    return this.newsItem;
  }
}
