import { Component, OnDestroy } from '@angular/core';
import { NbThemeService, NbToastrService } from '@nebular/theme';
import { takeWhile } from 'rxjs/operators';
import { SolarData } from '../../@core/data/solar';
import { Router } from '@angular/router';
import { URL } from '../../model/url';
import { CommonServiceService } from '../../common-service.service'
import { ToasterService } from 'angular2-toaster';

@Component({
  selector: 'ngx-forgotpassword',
  styleUrls: ['./forgotpassword.component.scss'],
  templateUrl: './forgotpassword.component.html',
})
export class ForgotpasswordComponent implements OnDestroy {

  private alive = true;
  user = {
    email: '',
  }


  navigateHome() {

  }

  constructor(private themeService: NbThemeService, public cms: CommonServiceService,
    private solarService: SolarData,
    private toastr: NbToastrService,
    private router: Router) { }

  ngOnDestroy() {
    this.alive = false;
  }

  forgotpassword() {
    let url = URL.resetPassword
    let payload = {
      EMAIL: this.user.email
    }
    this.cms.simplePost(url, payload).subscribe(res => {
      console.log("the res is", res);
      if (res.Status == 200) {
        this.toastr.success('An email was sent to you', 'Success'); 
        this.router.navigate(['/'])
      }

    })

  }
  login() {
    this.router.navigate(['/'])
  }

}
