import { NgModule } from '@angular/core';
import { CKEditorModule } from 'ng2-ckeditor';
import {
    NbActionsModule,
    NbButtonModule,
    NbCardModule,
    NbCheckboxModule,
    NbDatepickerModule, NbIconModule,
    NbInputModule,
    NbRadioModule,
    NbSelectModule,
    NbUserModule,
  } from '@nebular/theme';
import { ThemeModule } from '../../@theme/theme.module';
import { AccessHistoryComponent } from './accessHistory.component';
import { CommonServiceService } from "../../common-service.service";
// import { EditorsRoutingModule, routedComponents } from './editors-routing.module';
import {NgxPaginationModule} from 'ngx-pagination'; 

@NgModule({
  imports: [
    NbCardModule,
    ThemeModule,
    // EditorsRoutingModule,
    NbActionsModule,
    NbButtonModule,
    NbCheckboxModule,
    NbDatepickerModule, NbIconModule,
    NbInputModule,
    NbRadioModule,
    NbSelectModule,
    NbUserModule,
    CKEditorModule,
    NgxPaginationModule
  ],
  declarations: [
    AccessHistoryComponent
    // ...routedComponents,
  ],
  providers:[CommonServiceService]
})
export class AccessHistoryModule { }
