import { Component, OnDestroy } from '@angular/core'
import { NbThemeService, NbToastrService } from '@nebular/theme'
import { takeWhile } from 'rxjs/operators'
import { SolarData } from '../../@core/data/solar'
import { Router } from '@angular/router'
import { URL } from '../../model/url'
import { CommonServiceService } from '../../common-service.service'
import { ToasterConfig } from 'angular2-toaster'

@Component({
  selector: 'ngx-contact',
  styleUrls: ['./contact.component.scss'],
  templateUrl: './contact.component.html',
})
export class ContactComponent implements OnDestroy {
  private alive = true
  contact = {
    name: '',
    email: '',
    subject: '',
    reason: '',
  }
  userData: any

  constructor(
    private themeService: NbThemeService,
    public cms: CommonServiceService,
    private solarService: SolarData,
    private toastrService: NbToastrService,
    private router: Router,
  ) {
    this.userData = this.cms.getuserData()

    console.log('this.userData:', this.userData)

    this.contact = {
      name: this.userData.Fname + ' ' + this.userData.Lname,
      email: this.userData.Email,
      subject: '',
      reason: '',
    }
    // this.changePassword();
  }

  ngOnDestroy() {
    this.alive = false
  }
  error = []
  conatcvgw() {
    this.error = [];
    var { error } = this;
    if(!this.contact.name || this.contact.name =="")
    {
      error[0] = "Name is required"
    }
    if(!this.contact.email || this.contact.email =="")
    {
      error[1] = "Email is required"
    }
    if(!this.contact.subject || this.contact.subject =="")
    {
      error[2] = "Subject is required"
    }
    if(!this.contact.reason || this.contact.reason =="")
    {
      error[3] = "Reason is required"
    }

    if( this.error.length  != 0)
    {
      return null;
    }
    this.cms
      .commonPostCall(URL.CONTACT, this.contact)
      .subscribe((resp: any) => {
        if (resp.Status == 200) {
          this.toastrService.success(
            'We will reach out to you within 48 hours',
            'Success',
          )
        } else {
          this.toastrService.warning(resp.message, 'Error')
        }
      })

    // issueOrEnquiry
  }
}
