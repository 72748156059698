import { NgModule } from '@angular/core';
import { CKEditorModule } from 'ng2-ckeditor';

import { ThemeModule } from '../../@theme/theme.module';
import { LogoutComponent} from './logout.component';
// import { EditorsRoutingModule, routedComponents } from './editors-routing.module';

@NgModule({
  imports: [
    ThemeModule,

    CKEditorModule,
  ],
  declarations: [
    LogoutComponent
    // ...routedComponents,
  ],
})
export class LogoutModule { }
