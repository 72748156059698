import { RouterModule, Routes } from '@angular/router'
import { NgModule } from '@angular/core'

import { PagesComponent } from './pages.component'
import { DashboardComponent } from './dashboard/dashboard.component'
// import { ECommerceComponent } from './e-commerce/e-commerce.component';
// import { NotFoundComponent } from './miscellaneous/not-found/not-found.component';
import { CreateInvoiceComponent } from './create-invoice/create-invoice.component'
import { AddGatewayComponent } from './addGateway/addGateway.component'
import { AccessHistoryComponent } from './access-history/accessHistory.component'
import { NotificetionsComponent } from './notifications/notifications.component'
import { TransactionsComponent } from './transactions/transactions.component'
import { TransferComponent } from './transfer/transfer.component'
import { ViewNetworkComponent } from './view-network/viewNetwork.component'
import { PayInvoiceComponent } from './pay-invoice/pay-invoice.component'
import { GenerateAddressComponent } from './generateAddress/generateAddress.component'
import { ChangePasswordComponent } from './changepassword/changepassword.component'
import { LogoutComponent } from './logout/logout.component'
import { ViewNetworkUserComponent } from './view-network-user/viewNetworkUser.component'
import { MetricsComponent } from './metrics/metrics.component'
import { ContactComponent } from './contact/contact.component'
import { editprofileComponent } from './editprofile/editprofile.component'
import { ApprovalconfigComponent } from './approvalconfig/approvalconfig.component'
import { MyWalletComponent } from './my-wallet/my-wallet.component'
import { AuthGuard } from '../auth.guard'
import { SettingsComponent } from './settings/settings.component'
import { CheckAuthGuardService } from '../check-auth-guard.service'



const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    component: PagesComponent,
    children: [
      // {
      //   path:'login',
      //   component:LoginComponent
      // },
      // {
      //   path:'forgotpassword',
      //   component:ForgotpasswordComponent
      // },
      {
        path: 'changepassword',
        component: ChangePasswordComponent,
      },
      {
        path: 'contact',
        component: ContactComponent,
      },
      {
        path: 'editprofile',
        component: editprofileComponent,
      },

      {
        path: 'dashboard',
        component: DashboardComponent,
      },
      {
        path: 'create-invoice',
        component: CreateInvoiceComponent,
      },
      {
        path: 'add-gateway',
        component: AddGatewayComponent,
      },
      {
        path: 'apprmgmt',
        component: ApprovalconfigComponent,
      },
      {
        path: 'transactions',
        component: TransactionsComponent,
      },
      {
        path: 'transfer',
        component: TransferComponent,
      },
      {
        path: 'generateaddress',
        component: GenerateAddressComponent,
      },
      {
        path: 'notifications',
        component: NotificetionsComponent,
      },
      {
        path: 'access-history',
        component: AccessHistoryComponent,
      },
      {
        path: 'view-network',
        component: ViewNetworkComponent,
      },
      {
        path: 'metrics',
        component: MetricsComponent,
      },
      {
        path: 'mywallet',
        component: MyWalletComponent,
      },
      {
        path: 'viewuser',
        component: ViewNetworkUserComponent,
      },
      {
        path: 'pay-invoice',
        component: PayInvoiceComponent,
      },
      {
        path: 'logout',
        component: LogoutComponent,
      },
      {
        path: 'settings',
        component: SettingsComponent,
      },
    

      // {
      //   path: 'layout',
      //   loadChildren: () => import('./layout/layout.module')
      //     .then(m => m.LayoutModule),
      // },
      // {
      //   path: 'forms',
      //   loadChildren: () => import('./forms/forms.module')
      //     .then(m => m.FormsModule),
      // },
      // {
      //   path: 'ui-features',
      //   loadChildren: () => import('./ui-features/ui-features.module')
      //     .then(m => m.UiFeaturesModule),
      // },
      // {
      //   path: 'modal-overlays',
      //   loadChildren: () => import('./modal-overlays/modal-overlays.module')
      //     .then(m => m.ModalOverlaysModule),
      // },
      // {
      //   path: 'extra-components',
      //   loadChildren: () => import('./extra-components/extra-components.module')
      //     .then(m => m.ExtraComponentsModule),
      // },
      // {
      //   path: 'maps',
      //   loadChildren: () => import('./maps/maps.module')
      //     .then(m => m.MapsModule),
      // },
      // {
      //   path: 'charts',
      //   loadChildren: () => import('./charts/charts.module')
      //     .then(m => m.ChartsModule),
      // },
      // {
      //   path: 'editors',
      //   loadChildren: () => import('./editors/editors.module')
      //     .then(m => m.EditorsModule),
      // },
      // {
      //   path: 'tables',
      //   loadChildren: () => import('./tables/tables.module')
      //     .then(m => m.TablesModule),
      // },
      // {
      //   path: 'miscellaneous',
      //   loadChildren: () => import('./miscellaneous/miscellaneous.module')
      //     .then(m => m.MiscellaneousModule),
      // },
      // {
      //   path: '',
      //   redirectTo: 'dashboard',
      //   pathMatch: 'full',
      // },
      // {
      //   path: '**',
      //   component: NotFoundComponent,
      // },
    ],
  },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PagesRoutingModule {}
