import { Injectable } from '@angular/core'
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http'
import { Subject, Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import 'rxjs/add/operator/map'
import * as CryptoJS from 'crypto-js'
import { BehaviorSubject } from 'rxjs'

import 'rxjs/add/operator/toPromise'
@Injectable({
  providedIn: 'root',
})
export class CommonServiceService {
  encryptSecretKey = 'CRYPTO_TAX_APP'
  ip = ''
  headerCoin: Subject<any>
  getSelectCoinId = 1
  chooseCoin: Subject<any>
  Loader: Subject<any>
  AccountDisabled: Subject<any>
  coinsList = [];
  constructor(public http: HttpClient) {
    this.headerCoin = new Subject<any>()
    this.Loader = new Subject<any>()
    this.chooseCoin = new Subject<any>()
    this.AccountDisabled = new Subject<any>()
    this.getipAdress()
  }
  AccountLocked(msg) {
    this.AccountDisabled.next({ msg: msg })
  }

  loaderSet(status: any) {
    this.Loader.next({ status: status })
  }
  choosedCoinSelect() {
    this.chooseCoin.next({ choose: true })
  }
  
  choosedSet() {
    return this.chooseCoin.asObservable()
  }
  LoaderGet() {
    return this.Loader.asObservable()
  }
  AccountDisabledmessage() {
    return this.AccountDisabled.asObservable()
  }
  checkResponse(resp) {
    if (resp) {
      if (resp.Status) {
        if (resp.Status == 500) {
          this.AccountLocked(resp.message)
        }
      }
    }
  }
  getipAdress() {
    var url = 'https://api.ipify.org/?format=json' //"https://jsonip.com/";
    this.http.get(url).subscribe((res: any) => {
      this.ip = res.ip

      //   // localStorage.setItem("ip", res.ip);
      //  console.log("IpADress:",this.ip)
    })
  }
  getQrCode (url)
  {
    this.loaderSet(true)
    let headers = this.createAuthorizationHeader()
    return this.http.get(url, { headers: headers });
  }
  createAuthorizationHeader(): any {
    let key = this.decryptData(localStorage.getItem('vega')).PublicKey
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('vgwpay-key', key)
      .set('ip', this.ip)
    return headers
  }
  createIpHeader() {
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('ip', this.ip)
    return headers
  }
  encryptData(data) {
    try {
      return CryptoJS.AES.encrypt(
        JSON.stringify(data),
        this.encryptSecretKey,
      ).toString()
    } catch (e) {
      return e
    }
  }
  disableVerification()
  {
    return this.http.get('')
  }
  verifSecretCode(url,data)
  {
    this.loaderSet(true)
    let headers = this.createAuthorizationHeader();
    return this.http.post(url, data, { headers: headers }).map((response) => {
      this.loaderSet(false)
      // this.checkResponse(response)
      return response
    })
  }
  decryptData(data) {
    try {
      const bytes = CryptoJS.AES.decrypt(data, this.encryptSecretKey)
      if (bytes.toString()) {
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
      }
      return data
    } catch (e) {
      return e
    }
  }

  keyData(data) {
    try {
      var data = data
      const bytes = CryptoJS.AES.decrypt(data, this.encryptSecretKey)
      if (bytes.toString()) {
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
      }
      return data
    } catch (e) {
      return e
    }
  }

  simplePost(url, data): Observable<any> {
    this.loaderSet(true)
    let headers = this.createIpHeader()
    return this.http.post(url, data, { headers: headers }).map((response) => {
      this.loaderSet(false)
      // this.checkResponse(response)
      return response
    })
  }

  commonGetCall(api: string) {
    this.loaderSet(true)
    let headers = this.createAuthorizationHeader()
    return this.http.get(api, { headers: headers }).pipe(
      map((resp) => {
        this.loaderSet(false)
        this.checkResponse(resp)
        //this.loderStatus.next(false);
        return resp
      }),
    )
  }

  commonGetCall2(api: string) {
    // this.loaderSet(true)
    let headers = this.createAuthorizationHeader()
    return this.http.get(api, { headers: headers }).pipe(
      map((resp) => {
        // this.loaderSet(false)
        this.checkResponse(resp)
        //this.loderStatus.next(false);
        return resp
      }),
    )
  }
  getCoinList() {
    let headers = this.createAuthorizationHeader()
    return this.http.get('https://vegapay.vegawallet.com/api/vgw/getcoinlist', {
      headers: headers,
    })
  }
  changeCoinStatus(URL, payload) {
    let headers = this.createAuthorizationHeader()
    return this.http.post(URL, payload, { headers: headers })
  }

  commonGetCallNoLoader(api: string) {
    let headers = this.createAuthorizationHeader()
    return this.http.get(api, { headers: headers }).pipe(
      map((resp) => {
        this.checkResponse(resp)
        return resp
      }),
    )
  }

  createAuthorizationHeaderOtherToken(PublicKey): any {
    let key = this.decryptData(localStorage.getItem('vega')).PublicKey
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('parent', key)
      .set('vgwpay-key', PublicKey)
      .set('ip', this.ip)
    return headers
  }

  commonGetCallOtherToken(api, token) {
    this.loaderSet(true)
    let headers = this.createAuthorizationHeaderOtherToken(token)
    return this.http.get(api, { headers: headers }).pipe(
      map((resp) => {
        this.loaderSet(false)
        this.checkResponse(resp)
        //this.loderStatus.next(false);
        return resp
      }),
    )
  }

  commonPostCall(api: string, payload) {
    this.loaderSet(true)
    let headers = this.createAuthorizationHeader()
    return this.http.post(api, payload, { headers: headers }).pipe(
      map((resp) => {
        this.loaderSet(false)
        this.checkResponse(resp)
        //this.loderStatus.next(false);
        return resp
      }),
    )
  }

  commonPostCallNoLoader(api: string, payload) {
    let headers = this.createAuthorizationHeader()
    return this.http.post(api, payload, { headers: headers }).pipe(
      map((resp) => {
        this.checkResponse(resp)
        return resp
      }),
    )
  }

  getuserData() {
    try {
      var data = localStorage.getItem('vega')
      const bytes = CryptoJS.AES.decrypt(data, this.encryptSecretKey)
      if (bytes.toString()) {
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
      }
      return data
    } catch (e) {
      return e
    }
  }

  resendVerifLink(api, payload) {
    let headers = this.createAuthorizationHeader()
    return this.http.post(api, payload, { headers: headers })
  }
  setSelectCoin(value) {
    this.headerCoin.next(value)
  }

  getSelectCoin() {
    // return this.headerCoin.asObservable();
    return this.headerCoin
  }
  selecetedCoin: any
  setCoinId(id) {
    return (this.getSelectCoinId = id)
  }

  coinList = [
    {
      coinId: 1,
      coinName: 'DASH',
      fullName: 'Dash',
      toolTip: '',
      isActive: true,
      environment: 'test',
      displayName: 'Dash',
      endPoint: 'apidash',
      icon: 'https://www.cryptocompare.com/media/33842920/dash.png',
    },
  ]
  setCoinList(list) {
    this.coinList = list
    console.log('My Coin List', this.coinList)
  }

  navigateTransction(id, type, coin) {
    console.log(id, type, coin)

    if (type == 'txid') {
      if (coin == 'DASH' || coin == 'BTC') {
        var url = `https://sochain.com/tx/${coin}/${id}`

        window.open(url)
      } else if (coin == 'ETH') {
        var url = `https://etherscan.io/tx/${id}`
        window.open(url)
      } else if (coin == 'VGW') {
        var url = ` https://ethplorer.io/tx/${id}`
        window.open(url)
      }
    } else if (type == 'address') {
      if (coin == 'DASH' || coin == 'BTC') {
        var url = `https://sochain.com/address/${coin}/${id}`

        window.open(url)
      } else if (coin == 'ETH') {
        var url = `https://etherscan.io/address/${id}`
        window.open(url)
      } else if (coin == 'VGW') {
        var url = ` https://ethplorer.io/address/${id}`
        window.open(url)
      }
    }
  }
}
