import { NgModule } from '@angular/core';
import { CKEditorModule } from 'ng2-ckeditor';
import {
    NbActionsModule,
    NbButtonModule,
    NbCardModule,
    NbCheckboxModule,
    NbDatepickerModule, NbIconModule,
    NbInputModule,
    NbRadioModule,
    NbSelectModule,
    NbUserModule,
  } from '@nebular/theme';
import { ThemeModule } from '../../@theme/theme.module';
import { MyWalletComponent } from './my-wallet.component';
import {NgxPaginationModule} from 'ngx-pagination'; 
import { ChartsModule } from 'ng2-charts';

@NgModule({
  imports: [
    
    ChartsModule,
    NbCardModule,
    ThemeModule,
    // EditorsRoutingModule,
    NbActionsModule,
    NbButtonModule,
    NbCheckboxModule,
    NbDatepickerModule, NbIconModule,
    NbInputModule,
    NbRadioModule,
    NbSelectModule,
    NbUserModule,
    CKEditorModule,
    NgxPaginationModule
  ],
  declarations: [
    MyWalletComponent
    // ...routedComponents,
  ],
})
export class MyWalletModule { }
