import { NgModule } from '@angular/core';
import { CKEditorModule } from 'ng2-ckeditor';
import { FormsModule } from '@angular/forms';
import {NgxPaginationModule} from 'ngx-pagination';

import {
    NbActionsModule,
    NbButtonModule,
    NbCardModule,
    NbCheckboxModule,
    NbDatepickerModule, 
    NbIconModule,
    NbInputModule,
    NbRadioModule,
    NbSelectModule,
    NbUserModule,
    NbTooltipModule,
  } from '@nebular/theme';
import { ThemeModule } from '../../@theme/theme.module';
import { TransactionsComponent } from './transactions.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
// import { EditorsRoutingModule, routedComponents } from './editors-routing.module';

@NgModule({
  imports: [

    NgxPaginationModule,
    Ng2SearchPipeModule,
    NbCardModule,
    FormsModule,
    ThemeModule,
    // EditorsRoutingModule,
    NbActionsModule,
    NbButtonModule,
    NbCheckboxModule,
    NbDatepickerModule, NbIconModule,
    NbInputModule,
    NbRadioModule,
    NbSelectModule,
    NbUserModule,
    CKEditorModule,
    NbTooltipModule
  ],
  declarations: [
    TransactionsComponent
    // ...routedComponents,
  ],
})
export class TransactionsModule { }
