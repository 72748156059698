import { Component, OnDestroy } from '@angular/core';
import { NbThemeService, NbToastrService } from '@nebular/theme';
import { SolarData } from '../../@core/data/solar';
import { takeWhile } from "rxjs/operators";
import { CommonServiceService } from "../../common-service.service";
import { URL } from "../../model/url";
import { ToasterConfig } from 'angular2-toaster';
import 'style-loader!angular2-toaster/toaster.css';
import { Router, ActivatedRoute } from "@angular/router";
import { FormControl } from '@angular/forms';


interface CardSettings {
  title: string;
  iconClass: string;
  type: string;
}

@Component({
  selector: 'ngx-Approvalconfig',
  styleUrls: ['./approvalconfig.component.scss'],
  templateUrl: './approvalconfig.component.html',
})
export class ApprovalconfigComponent implements OnDestroy {
  config: ToasterConfig;
  private alive = true;
  userData: any;
  userList = [];
  constructor(
    private toastrService: NbToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private themeService: NbThemeService,
    public cms: CommonServiceService,
    private solarService: SolarData
  ) {
    this.userData = this.cms.getuserData();
   
    this.GetList();


  }

  ApprovalList = [];

  choosedadmin : string;
  ChooseAdmin(value : any){

    let user = this.AdminList.find(x=>x.CmID==value);
    
    var url = URL.ADD_ADMIN_APPROVAL_LIST;
   this.cms.commonPostCall(url,user).subscribe((res: any) => {    
      if (res.Status == 200) {       
        this.toastrService.success(res.message,"Success");
        this.choosedadmin = null;
        this.GetList();

      }
      else {
        this.toastrService.warning(res.message, "Error")
      }
    });
  }


  AdminList = []


  GetList ()
  {
   var url = URL.GETADMINLIST;
    this.cms.commonGetCall(url).subscribe((res: any) => {
      console.log("Users", res);
      if (res.Status == 200) { 
        this.AdminList =  res.Data.filter(x=>!x.ApprovalSetup);

        this.ApprovalList = res.Data.filter(x=>x.ApprovalSetup);

      }
      else {
        this.toastrService.warning(res.message, "Error")
      }


    });
  }

  remove(user)
  {
    
    var url = URL.DELETE_ADMIN_APPROVAL_LIST;
    this.cms.commonPostCall(url,user).subscribe((res: any) => {    
       if (res.Status == 200) {       
         this.toastrService.success(res.message,"Success");
         this.GetList();
       }
       else {
         this.toastrService.warning(res.message, "Error")
       }
     });

  }


  ngOnDestroy() {
    this.alive = false;
  }
}
