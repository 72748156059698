import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core'
import {
  NbThemeService,
  NbToastrService,
  NbDialogService,
} from '@nebular/theme';
import { takeWhile } from 'rxjs/operators';
import { SolarData } from '../../@core/data/solar';
import { CommonServiceService } from '../../common-service.service';
import { URL } from '../../model/url';
import { ToasterConfig } from 'angular2-toaster';
import 'style-loader!angular2-toaster/toaster.css';
import { Router, ActivatedRoute } from '@angular/router';
// import { providers, getDefaultProvider } from 'ethers'
import {environment} from '../../../environments/environment' 

// const provider = providers.BaseProvider(1);

interface CardSettings {
  title: string
  iconClass: string
  type: string
}

@Component({
  selector: 'ngx-transfer',
  styleUrls: ['./transfer.component.scss'],
  templateUrl: './transfer.component.html',
})
export class TransferComponent implements OnDestroy, OnInit {
  dollorvolume: number = 0
  fee: any
  transferamount: any

  DashMarketPrice: 0
  EthMarketPrice: 0
  BtcMarketPrice: 0
  VgwMarketPrice: 0

  payload = {
    coinId: null,
    volume: null,
    TransferAddress: '',
    Comment: '',
  }
  userData: any
  selectCoinData = {
    coinId: null,
    coinName: '',
    fullName: '',
    toolTip: '',
    isActive: false,
    environment: '',
    displayName: '',
    endPoint: '',
    icon: '',
    Price: 0,
  }
  CoinBalance = 0
  tempval = 0;
  minumamount = 2;

  private alive = true

  role: any

  selectcoinName: ''
  secretCode = "";
  qrCode: any;
  qrCodeText = "";
  coinVolume: number;
  addressList = [
    "XaqyuqVP3wik5WYyV8XRv9zhktsTqM6R8w",
    "0xeb2585d093825FfB39E685A70B60F3eb39aC1A4F",
    "1BaASj2tK2LGCWU2vszZpSB2Uz6UGtLJgr",
    "0xeb2585d093825FfB39E685A70B60F3eb39aC1A4F"
  ]


  constructor(
    private toastrService: NbToastrService,
    private cms: CommonServiceService,
    private themeService: NbThemeService,
    private router: Router,
    private solarService: SolarData,
    private dialogService: NbDialogService,
  ) {
    this.role = this.cms.getuserData().Role

    var coin = localStorage.getItem('coin')
    if (coin) {
      this.selectCoinData = JSON.parse(coin)
      let coinis = JSON.parse(coin)

      this.getCoinmarketValue()
      // this.getBalanceByCoin(this.selectCoinData);
      // this.dollorvolume = 0;
      // this.payload.volume = "0";

      console.log('coin data;', this.selectCoinData.coinName);
      this.GasFee = 0;
      this.getGasPrice(this.selectCoinData.coinId)
      // if(this.selectCoinData.coinId == 2 || this.selectCoinData.coinId == 4){
      // this.getGasPrice(this.selectCoinData.coinId)
      // }

    }

  }

  ngOnInit(): void {
    this.check2faEnableordisable();

    // console.log("pppppppppppppppppppppppppppp",provider)

  }
  GasFee;
  getGasPrice(id) {
    let url = URL.GAS_PRICE;
    let payload = {
      coinId: id
    }
    this.cms
      .commonPostCall(url, payload)
      .subscribe((resp: any) => {
        if (resp.Status == 200) {
          if(id == 1)
          {
            this.GasFee  = 0.0005;
          }
          else if(id == 3)
          {
            this.GasFee  = 0.00006;
          } else 
          {
            this.GasFee = Number(resp.Data.result)
          }
         
        }
        console.log("TestData:", resp)
      })

  }

  LatestPrices = []

  loadQrCode(dialog) {
    this.cms.getQrCode(URL.GET_QR_CODE).subscribe((data) => {
      let result: any = data
      this.qrCode = result.Data.imagedata;
      this.qrCodeText = result.Data.secretcode;
      this.open(dialog)

    })

  }
  enable2fa = false;
  check2faEnableordisable() {
    this.cms.getQrCode(URL.CHECK_2FA).subscribe((data: any) => {
      this.cms.loaderSet(false);
      if (data.Status == 200) {
        this.enable2fa = data.Data.enable2fa;

      }
      // console.log(data)
    })

  }

  disable2faauth(dialog) {
    this.open(dialog)
  }

  disable2FAapp(ref) {
    this.cms
      .verifSecretCode(URL.VERIF_2FA, {
        otp: this.otp
      })
      .subscribe((data) => {
        let result: any = data
        if (result.Status == 400) {
          this.toastrService.warning(result.message, 'Error')
        } else {
          // this.toastrService.success(result.message, 'success');
          this.otp = "";
          this.closeEnable(ref);
          this.disable2FA(ref)
          // this.otp = "";
          // ref.close()
          // this.transferToMainWallet()
        }
        this.secretCode = '';
      })

  }
  open(dialog: TemplateRef<any>) {
    this.dialogService.open(dialog, {
      context: 'this is some additional data passed to dialog',
    })
  }
  transferToMain(dialog: TemplateRef<any>) {
    this.dialogService.open(dialog, {})
  }
  closeTransfer(ref) {

    this.cms
      .verifSecretCode(URL.VERIF_2FA, {
        otp: Number(this.secretCode),
      })
      .subscribe((data) => {
        let result: any = data
        if (result.Status == 400) {

          this.toastrService.warning(result.message, 'Error')
        } else {
          ref.close()
          this.secretCode = '';
          this.transferToMainWallet()
        }


      })
  }


  closeTransfertoTherwallet(ref) {
    this.cms
      .verifSecretCode(URL.VERIF_2FA, {
        otp: Number(this.secretCode),
      })
      .subscribe((data) => {
        let result: any = data
        if (result.Status == 400) {

          this.toastrService.warning(result.message, 'Error')
        } else {
          ref.close()
          this.secretCode = '';
          this.transfertootherWallet()
        }


      })

  }
  getCoinmarketValue() {
    this.cms.commonGetCall(URL.coinMarket).subscribe((x: any) => {
      if (x.Status == 200) {
        var resp = x.Data
        resp.forEach((x) => {
          if (x.name == 'Bitcoin') {
            this.BtcMarketPrice = x.price
          }
          if (x.name == 'Dash') {
            this.DashMarketPrice = x.price
          }
          if (x.name == 'Ethereum') {
            this.EthMarketPrice = x.price
          }
          if (x.name == 'VegaWallet Token') {
            this.VgwMarketPrice = x.price
          }
        })

        this.LatestPrices = resp;
        this.getDefaultData()
      }
    })
  }
  otp = "";

  enable2FA(ref, isFirst) {

    this.cms
      .verifSecretCode(URL.VERIF_2FA, {
        otp: Number(this.otp),
        isFirst: isFirst
      })
      .subscribe((data) => {
        let result: any = data
        if (result.Status == 400) {
          this.toastrService.warning(result.message, 'Error')
        } else {
          this.toastrService.success(result.message, 'success');
          this.otp = "";
          this.check2faEnableordisable();
          this.closeEnable(ref)
          // ref.close();
          // this.transferToMainWallet()
        }
        this.secretCode = '';
      })
  }
  closeEnable(ref) {
    ref.close();
    this.cms.loaderSet(false);
    // return null;
  }

  dolorentered(val) {
    if (val >= this.minumamount) {
      // if(val){
      this.error[1] = ''
      this.tempval = val

      // if (this.selectCoinData.coinName == 'DASH') {
      //   this.payload.volume = (val / this.DashMarketPrice).toString()
      //   this.fee = ((val / this.DashMarketPrice) * 0.02).toFixed(8)

      //   this.transferamount = (val / this.DashMarketPrice - this.fee).toFixed(8)
      // }
      // if (this.selectCoinData.coinName == 'ETH') {
      //   this.payload.volume = (val / this.EthMarketPrice).toString()
      //   this.fee = ((val / this.EthMarketPrice) * 0.02).toFixed(8)
      //   this.transferamount = (val / this.EthMarketPrice - this.fee).toFixed(8)
      // }
      // if (this.selectCoinData.coinName == 'BTC') {
      //   this.payload.volume = (val / this.BtcMarketPrice).toString()
      //   this.fee = ((val / this.BtcMarketPrice) * 0.02).toFixed(8)
      //   this.transferamount = (val / this.BtcMarketPrice - this.fee).toFixed(8)
      // }
      // if (this.selectCoinData.coinName == 'VGW') {
      //   this.payload.volume = (val / this.VgwMarketPrice).toString()
      //   this.fee = ((val / this.VgwMarketPrice) * 0.02).toFixed(8)
      //   this.transferamount = (val / this.VgwMarketPrice - this.fee).toFixed(8)
      // }
      /////////////Changing the Fixed
      if (this.selectCoinData.coinName == 'DASH') {
        this.payload.volume = (val / this.DashMarketPrice).toString()
        this.coinVolume = Number(this.payload.volume)
        this.fee = ((val / this.DashMarketPrice) * 0.002).toString()
        if (Number(this.fee * this.DashMarketPrice) < 1) {
          this.fee = 1 / this.DashMarketPrice;
        }
        this.transferamount = (val / this.DashMarketPrice - this.fee).toString()
      }
      if (this.selectCoinData.coinName == 'ETH') {
        this.payload.volume = (val / this.EthMarketPrice).toString()
        this.coinVolume = Number(this.payload.volume)
        this.fee = ((val / this.EthMarketPrice) * 0.002).toString()
        if (Number(this.fee * this.EthMarketPrice) < 1) {
          this.fee = 1 / this.EthMarketPrice;
        }

        this.transferamount = (val / this.EthMarketPrice - this.fee - parseFloat(this.GasFee)).toString()
      }
      if (this.selectCoinData.coinName == 'BTC') {
        this.payload.volume = (val / this.BtcMarketPrice).toString()
        this.coinVolume = Number(this.payload.volume)
        this.fee = ((val / this.BtcMarketPrice) * 0.002).toString()
        if (Number(this.fee * this.BtcMarketPrice) < 1) {
          this.fee = 1 / this.BtcMarketPrice;
        }
        this.transferamount = (val / this.BtcMarketPrice - this.fee - parseFloat(this.GasFee)).toString()
      }
      if (this.selectCoinData.coinName == 'VGW') {
        this.payload.volume = (val / this.VgwMarketPrice).toString()
        this.coinVolume = Number(this.payload.volume)
        this.fee = ((val / this.VgwMarketPrice) * 0.002).toString()
        if (Number(this.fee * this.VgwMarketPrice) < 1) {
          this.fee = 1 / this.VgwMarketPrice;
        }
        this.transferamount = (val / this.VgwMarketPrice - this.fee - parseFloat(this.GasFee)).toString()
      }

      console.log('this.payload.volume', this.payload.volume)

      this.coinVolume = this.decimalView(this.coinVolume)
    } else {
      this.error[1] = `please enter minimum  Withdraw $${this.minumamount}`
      this.payload.volume = null;
      this.tempval = val
      this.coinVolume = 0
    }
    ////////////
  }
  coinToDollar(val) {
    if (val > 0) {
      // if(val){
      this.error[1] = ''
      this.tempval = val

      // if (this.selectCoinData.coinName == 'DASH') {
      //   this.payload.volume = (val / this.DashMarketPrice).toString()
      //   this.fee = ((val / this.DashMarketPrice) * 0.02).toFixed(8)

      //   this.transferamount = (val / this.DashMarketPrice - this.fee).toFixed(8)
      // }
      // if (this.selectCoinData.coinName == 'ETH') {
      //   this.payload.volume = (val / this.EthMarketPrice).toString()
      //   this.fee = ((val / this.EthMarketPrice) * 0.02).toFixed(8)
      //   this.transferamount = (val / this.EthMarketPrice - this.fee).toFixed(8)
      // }
      // if (this.selectCoinData.coinName == 'BTC') {
      //   this.payload.volume = (val / this.BtcMarketPrice).toString()
      //   this.fee = ((val / this.BtcMarketPrice) * 0.02).toFixed(8)
      //   this.transferamount = (val / this.BtcMarketPrice - this.fee).toFixed(8)
      // }
      // if (this.selectCoinData.coinName == 'VGW') {
      //   this.payload.volume = (val / this.VgwMarketPrice).toString()
      //   this.fee = ((val / this.VgwMarketPrice) * 0.02).toFixed(8)
      //   this.transferamount = (val / this.VgwMarketPrice - this.fee).toFixed(8)
      // }
      /////////////Changing the Fixed
      if (this.selectCoinData.coinName == 'DASH') {
        this.payload.volume = (val * this.DashMarketPrice).toString()
        this.dollorvolume = Number(this.payload.volume)
        this.fee = (val * this.DashMarketPrice * 0.02).toString()
        if (Number(this.fee * this.DashMarketPrice) < 1) {
          this.fee = 1 / this.DashMarketPrice;
        }
        this.transferamount = (val * this.DashMarketPrice - this.fee - parseFloat(this.GasFee)).toString()
      }
      if (this.selectCoinData.coinName == 'ETH') {
        this.payload.volume = (val * this.EthMarketPrice).toString()
        this.dollorvolume = Number(this.payload.volume)
        this.fee = (val * this.EthMarketPrice * 0.02).toString();
        if (Number(this.fee * this.EthMarketPrice) < 1) {
          this.fee = 1 / this.EthMarketPrice;
        }
        this.transferamount = (val * this.EthMarketPrice - this.fee - parseFloat(this.GasFee)).toString()
      }
      if (this.selectCoinData.coinName == 'BTC') {
        this.payload.volume = (val * this.BtcMarketPrice).toString()
        this.dollorvolume = Number(this.payload.volume)
        this.fee = (val * this.BtcMarketPrice * 0.02).toString()
        if (Number(this.fee * this.DashMarketPrice) < 1) {

          this.fee = 1 / this.DashMarketPrice;
        }
        this.transferamount = (val * this.BtcMarketPrice - this.fee - parseFloat(this.GasFee)).toString()
      }
      if (this.selectCoinData.coinName == 'VGW') {
        this.payload.volume = (val * this.VgwMarketPrice).toString()
        this.dollorvolume = Number(this.payload.volume)
        this.fee = (val * this.VgwMarketPrice * 0.02).toString();
        if (Number(this.fee * this.VgwMarketPrice) < 1) {
          this.fee = 1 / this.VgwMarketPrice;
        }
        this.transferamount = (val * this.VgwMarketPrice - this.fee - parseFloat(this.GasFee)).toString()
      }

      console.log('this.payload.volume', this.payload.volume)
    } else {
      this.error[1] = `please enter minimum  Withdraw $${this.minumamount}`;
      this.payload.volume = null;
      this.tempval = val
      this.coinVolume = 0
    }
    ////////////
  }
  getDefaultData() {
    this.userData = this.cms.getuserData()
    this.cms.getSelectCoin().subscribe((data: any) => {
      console.log('Selection Coin', data)
      this.selectCoinData = data
      this.getBalanceByCoin()
      this.payload.volume = null;
      this.payload.TransferAddress = ''
      this.payload.Comment = ''
      this.dollorvolume = 0;
      this.coinVolume = 0;
      this.GasFee = 0;
      // if (this.selectCoinData.coinId == 2 || this.selectCoinData.coinId == 4) {
        this.getGasPrice(this.selectCoinData.coinId)
      // }
      // this.dolorentered(this.tempval)
    })

    var coin = localStorage.getItem('coin')
    if (coin) {
      this.selectCoinData = JSON.parse(coin)

      this.getBalanceByCoin()

      // console.log("coin data;",coin)
    }
  }

  currentCoinPrice = 0

  getBalanceByCoin() {
    this.cms
      .commonGetCall(URL.getBalance + '/' + this.selectCoinData.coinId)
      .subscribe((resp: any) => {
        if (resp.Status == 200) {
          // console.log("Balance test balance", resp);
          this.CoinBalance = resp.Data.balance
          this.LatestPrices.forEach((x) => {
            if (
              x.symbol.toLocaleLowerCase() ==
              this.selectCoinData.coinName.toLocaleLowerCase()
            ) {
              this.selectCoinData.Price = x.price
              let amount = this.CoinBalance * x.price
              this.currentCoinPrice = Number(amount.toFixed(2))
            }
          })
        } else {
          //  alert(resp.message)
          this.toastrService.warning(resp.message, 'Error')
        }
      })
  }

  error = []
  transferWallet(dialog) {
    // let code = localStorage.getItem('twoFactor')
    if (this.enable2fa) {
      this.transferToMain(dialog)
    } else {
      this.toastrService.warning("your account is not configured for two-factor authentication", 'Error')
      // this.transferToMainWallet()
    }
  }

  transfertootherWallet() {
    this.error = []

    if (!this.payload.TransferAddress) {
      this.error[0] = 'Withdraw Address is required'
    }
    if (!this.dollorvolume) {
      this.error[1] = 'Withdraw volume  is required'
    }
    if (Number(this.payload.volume) > this.CoinBalance) {
      this.error[2] = 'Enter volume greater than your wallet'
    }
    if (!Number(this.payload.volume)) {
      this.error[1] = `please enter minimum Withdraw $${this.minumamount}`
    }

    if (this.error.length != 0) {
      return null
    }

    //  let v = parseFloat(this.payload.volume) - parseFloat(this.fee);
    //  this.payload.volume = v;

    this.payload.coinId = this.selectCoinData.coinId;

    const transferPayload = {
      Comment: this.payload.Comment,
      TransferAddress: this.payload.TransferAddress,
      coinId: this.payload.coinId,
      volume: parseFloat(this.payload.volume) - parseFloat(this.GasFee),
    }


    this.cms
      .commonPostCall(URL.TransferToOtherWallet, transferPayload)
      .subscribe((resp: any) => {
        if (resp.Status == 200) {
          this.toastrService.success(resp.message, 'Success')
          this.getBalanceByCoin()
          this.router.navigate(['/dashboard'])
        } else {
          this.toastrService.warning(resp.message, 'Error')
        }
      })
  }
  showTransferToOtherWalletDialog(dialog: TemplateRef<any>) {
    this.dialogService.open(dialog, {})
  }
  transferSecondaryWallet(dialog) {
    // let code = localStorage.getItem('twoFactor')
    // if (code) {
    //   this.showTransferToOtherWalletDialog(dialog)
    // } else {
    //   this.transfertootherWallet()
    // }

    this.error = []

    if (!this.payload.TransferAddress) {
      this.error[0] = 'Withdraw Address is required'
    }
    if (!this.dollorvolume) {
      this.error[1] = 'Withdraw volume  is required'
    }
    if (Number(this.payload.volume) > this.CoinBalance) {
      this.error[2] = 'Enter volume greater than your wallet'
    }
    if (!Number(this.payload.volume)) {
      this.error[1] = `please enter minimum Withdraw $${this.minumamount}`
    }

    if (this.error.length != 0) {
      return null
    }

    if (this.enable2fa) {
      this.showTransferToOtherWalletDialog(dialog)
    } else {
      this.toastrService.warning("your account is not configured for two-factor authentication", 'Error')
      // this.transferToMainWallet()
    }
  }
  confirmTransfer(ref) {
    this.cms
      .verifSecretCode(URL.VERIF_2FA, {
        otp: this.secretCode,
      })
      .subscribe((data) => {
        let result: any = data
        if (result.Status == 400) {
          this.toastrService.warning(result.message, 'Error')
        } else {
          this.transfertootherWallet()
        }
        this.secretCode = ''
        console.log(result)
      })

    ref.close()
  }
  transferToMainWallet() {
    this.error = []

    if (!this.CoinBalance) {
      this.error[3] = "your wallet volume is '0'"

      return null
    }

    var obj = {
      coinId: this.selectCoinData.coinId,
    }
    this.cms
      .commonPostCall(URL.TransferTomainWallet, obj)
      .subscribe((resp: any) => {
        if (resp.Status == 200) {
          // alert(resp.message)
          this.toastrService.success(resp.message, 'Success')
          this.getBalanceByCoin()
        } else {
          this.toastrService.warning(resp.message, 'Error')
        }
      })
  }

  ngOnDestroy() {
    this.alive = false
  }
  navigation(url) {
    this.router.navigateByUrl(url)
  }
  disable2FA(ref) {
    this.cms.commonGetCall(URL.DISABLE_2FA).subscribe((data) => {
      let result: any = data;
      this.check2faEnableordisable();
      this.toastrService.success('2FA has been succefully disabled', 'Success')
      localStorage.removeItem('twoFactor')
    })
  }
  precision(num) {
    if (num) {
      let len = 8;
      var num = num.toString();
      var s = num.split(".")

      if (s.length == 2) {
        if (s[1].length > len) {
          return parseFloat(s[0] + "." + s[1].slice(0, len));
        }
        else {

          return parseFloat(num)
        }

      }
      else {
        return parseFloat(num)
      }
    }
    else {
      return "";
    }

  }


  decimalView(value)
  {
    if (value) {
      var num = value.toString();
      var s = num.split(".");
      if (s.length == 2) {
        if (s[1].length < environment.dicimal) {
          let v = parseFloat(value)
          return v.toFixed(environment.dicimal)
        }
        else {
          return parseFloat(s[0] + "." + s[1].slice(0, environment.dicimal));
        }
      } else {
        let v = parseFloat(value)
        return v.toFixed(environment.dicimal)
      }
    }
    else {
      return value;
    }

  }

}
