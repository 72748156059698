import { Component, OnInit,Input } from '@angular/core';
import { CommonServiceService } from "../../../common-service.service";
import { URL } from "../../../model/url";
import { Subscription } from "rxjs";
@Component({
  selector: 'ngx-transctions',
  templateUrl: './transctions.component.html',
  styleUrls: ['./transctions.component.scss']
})
export class TransctionsComponent implements OnInit {
  check_Transction = false;
  // @Input()
  // coinId = null
  subscription: Subscription
  constructor(
    private cms : CommonServiceService

  ) { }
  userData: any;
  selectCoinData = {
    coinId: null,
    coinName: "",
    fullName: "",
    toolTip: "",
    isActive: "",
    environment: "",
    displayName: "",
    endPoint: "",
    icon: "",
  }

  ngOnInit() {
    // if(this.coinId)
    // {
    //   this.getTransactionsByCoinId()
    // }

    this.userData = this.cms.getuserData();
    this.subscription =  this.cms.getSelectCoin()
      .subscribe((data: any) => {
        if (data.coinId) {
          this.selectCoinData = data;
          this.check_Transction = false;
          this.getTransactionsByCoinId();
        }
      });

       this.getTransactionsByCoinId();
    var coin = localStorage.getItem("coin");
    console.log('aaaaaaaaaa:',coin)
    if (coin) {
      this.check_Transction = false;
      this.selectCoinData = JSON.parse(coin);
      // this.cms.setSelectCoin(this.selectCoinData);
      this.getTransactionsByCoinId();

    }

  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
    // this.statusChanges.unsubscribe();
  }
  getAllTransactions = [];

  getTransactionsByCoinId() {

    console.log("CheckUrl:",URL.getTransaction + "/" + this.selectCoinData.coinId)
    this.cms.commonGetCall(URL.getTransaction + "/" + this.selectCoinData.coinId)
      .subscribe((resp: any) => {
        if (resp.Status == 200) {

          this.getAllTransactions = resp.Data;
          console.log("the trans data issssss.....", resp.Data);

          this.getAllTransactions.forEach(x => {
            x.time = new Date(x.time * 1000).toISOString();
            // x.time = new Date(x.time);
          })
          // this.sortData();
          this.getAllTransactions = this.getAllTransactions.sort((a, b) => {
            return <any>new Date(b.time) - <any>new Date(a.time);
          });
          // console.log("DAsh transactions", this.getAllTransactions);
          // this.getAllTransactions.slice(5)

          if(this.getAllTransactions.length == 0){
            this.check_Transction = true;
          }

        }
        else {
          this.getAllTransactions = [];
        }
      })

  }


}
