import { Component, OnDestroy } from "@angular/core";
import { NbThemeService } from "@nebular/theme";
import { takeWhile } from "rxjs/operators";
import { SolarData } from "../../@core/data/solar";
import { CommonServiceService } from "../../common-service.service";
import { URL } from "../../model/url";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4geodata_worldLow from "@amcharts/amcharts4-geodata/worldLow";
interface CardSettings {
  title: string;
  iconClass: string;
  type: string;
}

@Component({
  selector: "ngx-accesshistory",
  styleUrls: ["./accessHistory.component.scss"],
  templateUrl: "./accessHistory.component.html"
})
export class AccessHistoryComponent implements OnDestroy {
  private alive = true;
  userData: any;
  page = 0;
  history = []; 
  p : number =1;
  prviousbtn : boolean = true
  nxtbtn : boolean = true
  config = {
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: 0
  };
  

  constructor(
    private themeService: NbThemeService,
    public cms: CommonServiceService,
    private solarService: SolarData
  ) {
    this.userData = this.cms.getuserData();
    console.log("userData", this.userData);
    this.getUserHistory();
    this.getCurrentHistory();
   
  }

  ngOnDestroy() {
    this.alive = false;
  }

  getUserHistory() {
    let url = URL.getUserLoginHistory;
    let payload = {
      CmID: this.userData.CmID,
      page: this.config.currentPage - 1
    };
    this.cms.commonPostCall(url, payload).subscribe((res: any) => {
      console.log("the login history", res);
      if (res.Status == 200) {
        this.history = res.Data;
        if (res.Data[0]) {
          // let count = res.Data[0].Count / 10;
          this.config.totalItems = Math.floor(res.Data[0].Count)
        }
 
      }
    });
    if(this.page == 0){
      this.prviousbtn = true
    }
  }
  changepagination(val) {
    console.log("hthe pagination val;uieee wqjhsfoaih", val);
  }


  CurrentLoghistory = {
    LHID: null,
    Network: "",
    City: "",
    Country: "",
    CountryCode: "",
    Latitude: null,
    Longitude: null,
    NetworkOrganization: "",
    IPAddress: "",
    StateCode: "",
    State: "",
    TimeZone: "",
    ZIP: "",
    DeviceType: "",
    CreatedDate: "",
    IsActive: null,
    ROLE: null,
    CmID: null,
    Count: null
  }

  getCurrentHistory() {
    let url = URL.getUserLoginHistory;
    let payload = {
      CmID: this.userData.CmID,
      page: 0
    };
    this.cms.commonPostCall(url, payload).subscribe((res: any) => {
      console.log("the login history", res);
      if (res.Status == 200) {
        if (res.Data.length != 0) {
          this.CurrentLoghistory = res.Data[0];



          // Create map instance
          var chart = am4core.create("chartdiv", am4maps.MapChart);

          // Set map definition
          chart.geodata = am4geodata_worldLow;

          // Set projection
          chart.projection = new am4maps.projections.Miller();

          // Create map polygon series
          var polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());

          // Make map load polygon (like country names) data from GeoJSON
          polygonSeries.useGeodata = true;

          // Configure series
          var polygonTemplate = polygonSeries.mapPolygons.template;
          
          polygonTemplate.tooltipText = "{name}";
        
      //    polygonTemplate.fill = am4core.color("#74B266");

          // Create hover state and set alternative fill color
        //  var hs = polygonTemplate.states.create("hover");
        //  hs.properties.fill = am4core.color("#367B25");

          // Remove Antarctica
          // polygonSeries.exclude = ["AQ"];

          // Add some data
          polygonSeries.data = [{
            "id": this.CurrentLoghistory.CountryCode,
            "name": this.CurrentLoghistory.Country,
            "value": 100,
            "fill": am4core.color("#56e39c")
          }];

          // Bind "fill" property to "fill" key in data
          polygonTemplate.propertyFields.fill = "fill";

          // Create image series
          var imageSeries = chart.series.push(new am4maps.MapImageSeries());

          // Create a circle image in image series template so it gets replicated to all new images
          var imageSeriesTemplate = imageSeries.mapImages.template;
          var circle = imageSeriesTemplate.createChild(am4core.Circle);
          circle.radius = 4;
          circle.strokeWidth = 2;
          circle.nonScaling = true;
          circle.tooltipText = "{title}";


        
        }



      }
    });

  }



  pageChanged(page)
  {
    this.config.currentPage = page;
    this.getUserHistory();
    // alert(page)
  }
}
