import { NgModule } from '@angular/core';
import { CKEditorModule } from 'ng2-ckeditor';
import { FormsModule }   from '@angular/forms';
import {
    NbActionsModule,
    NbButtonModule,
    NbCardModule,
    NbCheckboxModule,
    NbDatepickerModule, NbIconModule,
    NbInputModule,
    NbRadioModule,
    NbSelectModule,
    NbUserModule,
    
  } from '@nebular/theme';
import { ThemeModule } from '../../@theme/theme.module';
import { ForgotpasswordComponent } from './forgotpassword.component';

@NgModule({
  imports: [
    NbCardModule,
    ThemeModule,
    // EditorsRoutingModule,
    NbActionsModule,
    NbButtonModule,
    NbCheckboxModule,
    NbDatepickerModule, NbIconModule,
    NbInputModule,
    NbRadioModule,
    NbSelectModule,
    NbUserModule,
    CKEditorModule,
    FormsModule
  ],
  declarations: [
    ForgotpasswordComponent
    // ...routedComponents,
  ],
})
export class ForgotpasswordModules { }
