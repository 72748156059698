import { NgModule } from '@angular/core';
import { CKEditorModule } from 'ng2-ckeditor';
import {
    NbActionsModule,
    NbButtonModule,
    NbCardModule,
    NbCheckboxModule,
    NbDatepickerModule, NbIconModule,
    NbInputModule,
    NbRadioModule,
    NbSelectModule,
    NbUserModule,
  } from '@nebular/theme';
import { ThemeModule } from '../../@theme/theme.module';
import { ApprovalconfigComponent } from './approvalconfig.component';
import {  NgxPaginationModule } from 'ngx-pagination'; 
import { FormsModule }   from '@angular/forms';
// import { EditorsRoutingModule, routedComponents } from './editors-routing.module';

@NgModule({
  imports: [
    NbCardModule,
    ThemeModule,
    // EditorsRoutingModule,
    NbActionsModule,
    FormsModule,
    NbButtonModule,
    NbCheckboxModule,
    NbDatepickerModule, NbIconModule,
    NbInputModule,
    NbRadioModule,
    NbSelectModule,
    NbUserModule,
    CKEditorModule,
    NgxPaginationModule
  ],
  declarations: [
    ApprovalconfigComponent
    // ...routedComponents,
  ],
})
export class ApprovalconfigModule { }
