//const OURSERVER = "http://test-paymentgateway.vegawallet.com:5555/api/vgw/";
// const OURSERVER = "http://localhost:5555/api/vgw/";

//const OURSERVER = "http://vegapay.vegawallet.com:5555/api/vgw/";

// const OURSERVER = "http://192.168.43.111:5555/api/vgw/";
//const OURSERVER = 'https://vegapay.vegawallet.com/'
const OURSERVER = '/api/vgw/'
export const URL = {
  GrandAminCreate: OURSERVER + 'createGrandAdmin',
  login: OURSERVER + 'userlogin',
  signup: OURSERVER + 'createUser',
  lookup: OURSERVER + 'lookup/1',
  //getcoinlist: OURSERVER + "getcoinlist",
  getBalance: OURSERVER + 'getBalance',
  getTransaction: OURSERVER + 'getTransaction',
  getTransactionDownload: OURSERVER + 'getTransactionDownload/',
  getcoinlist: OURSERVER + 'getUserCoins',
  getTotalBalance: OURSERVER + 'getTotalBalance',
  getAllBalance: OURSERVER + 'getAllBalance',
  getChildTotalBalance: OURSERVER + 'getChildTotalBalance',
  getAllTransactionsHistory: OURSERVER + 'getAllTransactionsHistory',

  getTransactionHistoryTotal: OURSERVER + 'getTransactionHistoryTotal',
  ToggleCoin: OURSERVER + 'update',
  getMenuList: OURSERVER + 'menulist',
  createUser: OURSERVER + 'createUser',
  getAddress: OURSERVER + 'getAddress',
  updatePassword: OURSERVER + 'updatePassword',
  getUserLoginHistory: OURSERVER + 'getUserLoginHistory',
  coinMarket: OURSERVER + 'coinMarket',
  getApprovalList: OURSERVER + 'getApprovalList',
  approvalStatusUpdate: OURSERVER + 'approvalStatusUpdate',
  resetPassword: OURSERVER + 'resetPassword',
  TransferToOtherWallet: OURSERVER + 'transferToOtherWallet',
  getUserList: OURSERVER + 'getChildList/',
  getChildAllTransactionsHistory: OURSERVER + 'getChildAllTransactionsHistory',
  getChildData: OURSERVER + 'getChildData',
  getUser: OURSERVER + 'user/',
  CHILD_ACTIVE_INACTIVE: OURSERVER + 'ChildListActiveOrInActive',
  TransferTomainWallet: OURSERVER + 'transferToMainWallet',
  REQUEST_NEW_ACCOUNT: OURSERVER + 'newrequest',
  TwitterFeed: OURSERVER + 'twitterfeed',
  UPDATE_PROFILE: OURSERVER + 'updateProfile',
  CONTACT: OURSERVER + 'issueOrEnquiry',
  GETADMINLIST: OURSERVER + 'getAdminsList',
  ADD_ADMIN_APPROVAL_LIST: OURSERVER + 'addapprovallist',
  DELETE_ADMIN_APPROVAL_LIST: OURSERVER + 'deleteapprovallist',
  RESEND_LINK: OURSERVER + 'resendlink',
  CRYPTO_NEWS: 'https://vast-eyrie-13251.herokuapp.com/',
  GET_QR_CODE:OURSERVER+'twoFactorAuthentication',
  CHECK_2FA:OURSERVER+'check2fa',
  DISABLE_2FA:OURSERVER+'twoFactorAuthenticationDisable',
  VERIF_2FA:OURSERVER+'twoFactorVerification',
  GAS_PRICE:OURSERVER+'gasprice'
}
