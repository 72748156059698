import {Component, OnDestroy,OnInit} from '@angular/core';
import { NbThemeService, NbToastrService } from '@nebular/theme';
import { takeWhile } from 'rxjs/operators' ;
import { SolarData } from '../../@core/data/solar';
import { CommonServiceService } from "../../common-service.service";
import { URL } from "../../model/url";
import { ClipboardService } from 'ngx-clipboard';
import { ToasterConfig } from 'angular2-toaster';

interface CardSettings {
  title: string;
  iconClass: string;
  type: string;
}

@Component({
  selector: 'ngx-generateAddress',
  styleUrls: ['./generateAddress.component.scss'],
  templateUrl: './generateAddress.component.html',
})
export class GenerateAddressComponent implements OnInit {

    private alive = true;
    config: ToasterConfig;
  getAddressData: any;
  userData: any;

  constructor(private themeService: NbThemeService,
    private toastrService: NbToastrService,
    private _clipboardService: ClipboardService,
              private solarService: SolarData,public cms: CommonServiceService) {

  }
  createNewAddress(){
    this.getAddressByCoinId({coinId:this.cms.getSelectCoinId});
  }
  currency = {
    coinId: null,
    coinName: "",
    fullName: "",
    toolTip: "",
    isActive:"",
    environment: "",
    displayName: "",
    endPoint: "",
    icon: "",
    CurrentPrice:null
  }
  currentMarketValue="";
  ngOnInit() {
    this.cms.choosedCoinSelect()
    this.userData = this.cms.getuserData()
    this.cms.getSelectCoin()
    .subscribe((data:any)=>{
    console.log(data);
   this.currency=data;
      this.getAddressByCoinId(data);
    });

    var coin = localStorage.getItem("coin");
    if(coin)
    {
      this.currency=JSON.parse(coin);
      this.getAddressByCoinId(this.currency);

      // console.log("coin data;",coin)
    }
    // this.currency = this.cms.coinList.find(x=>x.coinId==this.cms.getSelectCoinId);
    
    
  }

  getCurrentMarketPrice(){
    // this.getAddressByCoinId({coinId:this.cms.getSelectCoinId});
    this.cms.commonGetCall(URL.coinMarket).subscribe((x:any)=>{

     
      var prices=x.Data.find(y=>y.symbol==this.currency.coinName);
      this.currency.CurrentPrice = prices.price
      console.log("Market Value prices:",prices)
    },
    err=>{
      console.log(err);
    })
  }

  getAddressByCoinId(obj) {
    let payload={
      "CoinID": obj.coinId
    }
    this.cms.commonPostCall(URL.getAddress,payload)
      .subscribe((resp: any) => {
        if (resp.Status == 200) {
          console.log("getAddresssssss", resp);
          this.getAddressData=resp.Data;
          this.getCurrentMarketPrice();
        }
        else {

        }
      })

  }

  copy(text: string) {
    this._clipboardService.copyFromContent(text);
    this.toastrService.success("Address successfully copied.", "Success");
  }

}
