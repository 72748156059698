import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import {LoginComponent} from '../app/pages/login/login.component';
import {SignupComponent} from '../app/pages/signup/signup.component'
import { ForgotpasswordComponent} from '../app/pages/forgotpassword/forgotpassword.component'
import {GrandadminComponent} from '../app/pages/grandadmin/grandadmin.component';
import {PagesComponent} from '../app/pages/pages.component';
import {
  NbAuthComponent,
  NbLoginComponent,
  NbLogoutComponent,
  NbRegisterComponent,
  NbRequestPasswordComponent,
  NbResetPasswordComponent,
} from '@nebular/auth';
import { CheckAuthGuardService } from './check-auth-guard.service';

const routes: Routes = [


  // {
  //   path: '',
  //   loadChildren: () => import('app/pages/pages.module')
  //     .then(m => m.PagesModule),
  // },

  // <ngx-one-column-layout>

  {
    path: '',
    canActivate: [CheckAuthGuardService],
    component: NbAuthComponent,
    children: [
      {
        path: '',
        component: LoginComponent,
      },
      {
        path: 'signup',
        component: SignupComponent,
      },

      {
        path: 'forgotpassword',
        component: ForgotpasswordComponent,
      },
      {
        path: 'grandadmin',
        component: GrandadminComponent,
      },
 
      // {
      //   path: 'login',
      //   component: NbLoginComponent,
      // },

      {
        path: 'register',
        component: NbRegisterComponent,
      },
      // {
      //   path: 'logout',
      //   component: NbLogoutComponent,
      // },
      {
        path: 'request-password',
        component: NbRequestPasswordComponent,
      },
      {
        path: 'reset-password',
        component: NbResetPasswordComponent,
      },
    ],
  },
  {
    path:'',
    component:PagesComponent
  },

  { path: '', redirectTo: '', pathMatch: 'full' },
  { path: '**', redirectTo: 'pages' },
];

const config: ExtraOptions = {
  useHash: false,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
