import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import {
  NbActionsModule,
  NbButtonModule,
  NbCardModule,
  NbTabsetModule,
  NbUserModule,
  NbRadioModule,
  NbSelectModule,
  NbListModule,
  NbIconModule,
} from "@nebular/theme";
import { AgmCoreModule } from "@agm/core";
import { NgxEchartsModule } from "ngx-echarts";
import { ThemeModule } from "../../@theme/theme.module";
import { DashboardComponent } from "./dashboard.component";
import { FormsModule } from "@angular/forms";
import { CommonServiceService } from "../../common-service.service";
import { LeafletModule } from "@asymmetrik/ngx-leaflet";
import { SharemoduleModule } from "../../share/sharemodule/sharemodule.module";
import { QRCodeModule } from "angular2-qrcode";
import { NewsmodalComponent } from "./newsmodal/newsmodal.component";
import { NewsService } from '../layout/news.service';
import { NewsitemService } from './newsitem.service';
 import { MetricsModule } from "../../pages/metrics/metrics.module";
@NgModule({
  imports: [
    LeafletModule,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyBXdBuD6cpJQ2nGkSByScoPFMkkwJMP_V8",
      libraries: ["places"],
    }),
    CommonModule,
    SharemoduleModule,
    FormsModule,
     MetricsModule,
    ThemeModule,
    NbCardModule,
    NbUserModule,
    NbButtonModule,
    NbTabsetModule,
    NbActionsModule,
    NbRadioModule,
    NbSelectModule,
    NbListModule,
    NbIconModule,
    NbButtonModule,
    NgxEchartsModule,
    QRCodeModule,
  ],
  declarations: [DashboardComponent, NewsmodalComponent],
  entryComponents: [NewsmodalComponent],
  providers: [CommonServiceService,NewsitemService],
 
})
export class DashboardModule {}
