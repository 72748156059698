import { Component, OnInit } from '@angular/core';

import { MENU_ITEMS } from './pages-menu';
import { CommonServiceService } from "../common-service.service";
import { URL } from "../model/url";
@Component({
  selector: 'ngx-pages',
  styleUrls: ['pages.component.scss'],
  template: `
    <ngx-one-column-layout>
      <nb-menu [items]="menu"></nb-menu>
      <router-outlet></router-outlet>     
   </ngx-one-column-layout>
  `,
})
export class PagesComponent implements OnInit {
  menu: any
  constructor(public commonService: CommonServiceService) {

  }
  ngOnInit() {

    let user = this.commonService.getuserData();

    // console.log("UserCheck:",user)

    this.menu = MENU_ITEMS.filter(x=>{
      return x.roles.find(y=>y==user.Role);
    }) ;

    if(user.isMainAdmin == true)
    {
      this.menu.push({
        
          title: 'Approval Management',
          icon: 'people-outline',
          link: '/apprmgmt',
          roles:[2,3,4,5],
        
      })
    }

    // this.commonService.commonGetCall(URL.getMenuList).subscribe((x: any) => {
    //   console.log(x.Data);
    //   let menuFromBackend = x.Data;
      
      // let originalData = [{ "MenuID": 27, "RoleId": 6, "MenuName": "Dashboard", "Tooltip": "Dashboard", "Navigation": "/Dashboard", "Icon": "insert_chart_outlined", "PriorityOrder": 1 }, 
      // { "MenuID": 28, "RoleId": 6, "MenuName": "Transactions", "Tooltip": "Transactions", "Navigation": "/Transactions", "Icon": "monetization_on", "PriorityOrder": 2 },
      //  { "MenuID": 29, "RoleId": 6, "MenuName": "Access History", "Tooltip": "Access History", "Navigation": "/History", "Icon": "list", "PriorityOrder": 3 },
      //   { "MenuID": 30, "RoleId": 6, "MenuName": "Generate Address", "Tooltip": "Generate Address", "Navigation": "/GenerateAddress", "Icon": "payment", "PriorityOrder": 4 }, 
      //   { "MenuID": 31, "RoleId": 6, "MenuName": "Confirmation Process", "Tooltip": "Payment", "Navigation": "/Payment", "Icon": "payment", "PriorityOrder": 5 }];
     

    // },
    //   err => {
    //     console.log(err);
    //   });

  }

}
