import { Component, OnInit } from '@angular/core';
import { ChartType } from 'chart.js';
import { MultiDataSet, Label } from 'ng2-charts';
import { CommonServiceService } from "../../common-service.service";
import { URL } from "../../model/url";
import { NbThemeService, NbToastrService } from '@nebular/theme';
import { ToasterConfig } from 'angular2-toaster';
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-metrics',
  templateUrl: './metrics.component.html',
  styleUrls: ['./metrics.component.scss']
})

export class MetricsComponent implements OnInit {

  userData: any;
  userList: any;
  countarray = []

  doughnutChartLabels: Label[] = [];
  doughnutChartData: MultiDataSet = [[]];
  doughnutChartType: ChartType = 'doughnut';

   options: any = {
    legend: { position: 'bottom' },
    cutoutPercentage: 70,

  }

  userID: string;

  constructor(public cms: CommonServiceService,
    private router: Router,
    private route: ActivatedRoute,
    private toastrService: NbToastrService,) {

    this.userData = this.cms.getuserData();

    // this.doughnutChartLabels = ['BMW', 'Ford', 'Tesla'];

    this.doughnutChartData = [[25, 25, 25]]
    var payload = {
      CmID: this.userData.CmID
    }
    let url = "";
    url = URL.getChildData
    this.cms.commonPostCall(url, payload).subscribe((res: any) => {
      console.log("Users", res);
      if (res.Status == 200) {
        var count = []
        var newdata = []
        var count1 = 0
        var count2 = 0
        var count3 = 0
        var count4 = 0

        this.userList = res.Data;

        console.log("child data issssss...", this.userList);

        this.userList.forEach(x => {
          this.doughnutChartLabels.push(x.ROLEName)
          count.push(x.count)
          //  this.doughnutChartData = [[x.count]] 
          //  this.doughnutChartData.push(x.count)
          this.countarray.push(x.count)
        })

        //  count1 = count[0]
        //  count2 = count[1]
        //  count3 = count[2]
        //  count4 = count[3]

        // newdata.push(count)
        // this.doughnutChartLabels = ['BMW', 'Ford', 'Tesla'];
        this.doughnutChartData = [this.countarray]
        console.log("my data issssss...", this.doughnutChartData);
      }
      else {
        this.toastrService.warning(res.message, "Error")
      }

    });

  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      //console.log("params",params)
      // if (params.user) {

      this.userID = atob(params.user);

      console.log("the user id isss....", this.userID);






      // }


    })
  }

  getuserData2() {
    var payload = {
      CmID: this.userData.CmID
    }
    let url = "";
    url = URL.getChildData
    this.cms.commonPostCall(url, payload).subscribe((res: any) => {
      console.log("Users", res);
      if (res.Status == 200) {
        var count = []
        var newdata = []
        this.userList = res.Data;

        console.log("child data issssss...", this.userList);

        //  this.userList.forEach(x=>{
        //    this.doughnutChartLabels.push(x.ROLEName)
        //    count.push(x.count)
        //   //  this.doughnutChartData = [[x.count]] 
        //   //  this.doughnutChartData.push(x.count)
        //  })


        // newdata.push(count)
        this.doughnutChartLabels = ['BMW', 'Ford', 'Tesla'];
        this.doughnutChartData = [[2, 22, 2]]
        console.log("my data issssss...", this.doughnutChartData);
      }
      else {
        this.toastrService.warning(res.message, "Error")
      }

    });

  }

}
