import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../../environments/environment'

@Pipe({
  name: 'dicemalview'
})
export class DicemalviewPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    console.log("environmentenvironmentenvironment:",environment.dicimal);
    try {
      if (value) {
        var num = value.toString();
        var s = num.split(".");
        if (s.length == 2) {
          if (s[1].length < environment.dicimal) {
            let v = parseFloat(value)
            return v.toFixed(environment.dicimal)
          }
          else {
            return parseFloat(s[0] + "." + s[1].slice(0, environment.dicimal));
          }
        } else {
          let v = parseFloat(value)
          return v.toFixed(environment.dicimal)
        }
      }
      else {
        return value;
      }
    }
    catch (err) {
      console.log("DATATATATA:", value)
      console.log("ERROR:", err.message)
    }

    // return value;
  }

}
