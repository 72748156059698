import { Component, OnDestroy } from '@angular/core';
import { NbThemeService, NbToastrService } from '@nebular/theme';
import { SolarData } from '../../@core/data/solar';
import { takeWhile } from "rxjs/operators";
import { CommonServiceService } from "../../common-service.service";
import { URL } from "../../model/url";
import { ToasterConfig } from 'angular2-toaster';
import 'style-loader!angular2-toaster/toaster.css';
import { Router, ActivatedRoute } from "@angular/router";


interface CardSettings {
  title: string;
  iconClass: string;
  type: string;
}

@Component({
  selector: 'ngx-viewnetwork',
  styleUrls: ['./viewNetworkUser.component.scss'],
  templateUrl: './viewNetworkUser.component.html',
})
export class ViewNetworkUserComponent implements OnDestroy {
  p: number = 1;
  name: ""
  AllBalance = []
  config: ToasterConfig;
  configAccessHistory: ToasterConfig;
  private alive = true;
  userData: any;
  config_Pagination = {
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: 0
  };
  AccessHistory_config_Pagination = {
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: 0,
    id: "one"
  };
  userList = [];
  usertabs = [
    {
      title: "View Network",
      tab: true
    },
    {
      title: "Transaction",
      tab: false
    },
    {
      title: "Access History",
      tab: false
    },
    {
      title: "Balance",
      tab: false
    }
  ]

  activeTab(usertab) {
    this.usertabs.forEach((x) => {
      x.tab = false;
    })
    usertab.tab = !usertab.tab;
  }
  userID = null;
  Token = null;
  coinId = null;
  currency = {
    coinId: null,
    coinName: "",
    fullName: "",
    toolTip: "",
    isActive: "",
    environment: "",
    displayName: "",
    endPoint: "",
    icon: "",
  }
  constructor(
    private toastrService: NbToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private themeService: NbThemeService,
    public cms: CommonServiceService,
    private solarService: SolarData
  ) {

    this.userData = this.cms.getuserData();
    this.cms.getSelectCoin()
      .subscribe(data => {
        // console.log(data);
        this.currency = data;
        if (this.userID) {
          this.getUserData();
        }

      });
    // console.log("userData", this.userData);
    //  this.getuserList();
    this.route.queryParams.subscribe(params => {
      //console.log("params",params)
      if (params.user) {


        this.userID = atob(params.user);

        var coin = localStorage.getItem("coin");
        if (coin) {
          this.currency = JSON.parse(coin);
          this.getUserData();
        }

        this.getuserList();
        // this.getUserHistory();


      }
      else {
        this.router.navigateByUrl('/view-network')
      }

    })


  }

  // getCoinsList() {
  //   this.cms.commonGetCall(URL.getcoinlist)
  //     .subscribe((resp: any) => {
  //       if (resp.Status == 200) {
  //         console.log("all coins list   .....", resp);
  //         if (resp.Data && resp.Data.length != 0) {
  //           let data = resp.Data;
  //           this.getcoinIds(data);
  //         }
  //       }

  //     })
  // }

  // getcoinIds(data) {
  //   this.getAllBalances()
  //   this.getTransactionsHystory()
  //   this.getUserHistory();
  //   data.forEach(x => {
  //     // this.getAllBalance(x);
  //     //  this.getTransactions(x);
  //     //  this.getUserHistory();
  //   })
  //   console.log("the balances data", this.AllBalance);

  // }

  getAllBalances() {

    this.cms.commonGetCallOtherToken(URL.getAllBalance, this.Token)
      .subscribe((x: any) => {
        console.log("all balancessss", x);
        this.AllBalance = x.Data
      },
        (err) => {
          console.log(err);
        }
      );


    // this.cms.commonGetCall(URL.getAllBalance).subscribe(
    //   (x: any) => {
    //     console.log("all balancessss",x);

    //     this.AllBalance = x.Data
    //   },
    //   (err) => {
    //     console.log(err);
    //   }
    // );
  }

  getTransactionsHystory() {
    this.cms.commonGetCall(URL.getChildAllTransactionsHistory).subscribe((resp: any) => {
      console.log("getTransactionsHystory", resp);



      if (resp.Status == 200) {

        this.getAllTransactions = resp.Data

      }
      else {

      }
    })
  }








  getTransactions = async (data) => {
    await this.cms.commonGetCall(URL.getTransactionHistoryTotal + "/" + data.coinId).subscribe((resp: any) => {
      if (resp.Status == 200) {
        if (resp.Data.length != 0) {
          resp.Data.forEach((x: any) => {
            x.CoinName = data.coinName
            // if(x.length != 0){
            //   x.forEach(y=>{
            this.getAllTransactions.push(x)
            this.sorting()
            //   })
            // }
          })
          console.log("coinlistt issss", this.getAllTransactions);
        }

      }
      else {

      }
    })
  }

  sorting() {
    this.getAllTransactions.sort(function (a, b) {
      return b.timereceived - a.timereceived;
      // return <any>new Date(b.time) - <any>new Date(a.time);
    });
  }



  getUserData() {

    var usergetUrl = URL.getUser + this.userID;
    this.cms.commonGetCall(usergetUrl).subscribe((res: any) => {
      if (res.Status == 200) {
        this.name = res.Data.Fname + res.Data.Lname

        if(res.Data.Role == 6)
        {
          this.usertabs = this.usertabs.filter(x=>x. title != "Balance")
        }
        this.Token = res.Data.PublicKey;
        this.getAllBalances();
        this.getTransaction();

        // this.getCoinsList();
      } else {
        this.toastrService.warning(res.message, "Error")
      }
      // this.getTransaction();
    })
    // this.getCoinsList();

  }
  getAllTransactions = [];


  viewUser(user) {

    let key = btoa(user.CmID);
    this.AllBalance = []
    this.router.navigateByUrl('/viewuser?user=' + key)
    //  console.log(key)
  }

  getTransaction() {
    this.cms.commonGetCallOtherToken(URL.getTransactionHistoryTotal + "/" + this.currency.coinId, this.Token)
      .subscribe((resp: any) => {
        if (resp.Status == 200) {
          console.log("transactions", resp);

          resp.Data.forEach((x: any) => {
            x.CoinName = this.currency.coinName;
          })

          // if()
          this.getAllTransactions = resp.Data
        }
        else {

        }
        this.getUserHistory();
      })
  }
  pageChanged(p) {
    this.config_Pagination.currentPage = p;
    this.getuserList();
  }

  getuserList() {
    var payload = {
      CMID: this.userID
    }
    let url = "";
    let page = this.config_Pagination.currentPage - 1;
    url = URL.getUserList + page;
    this.cms.commonPostCall(url, payload).subscribe((res: any) => {
      console.log("Users", res);
      if (res.Status == 200) {
        this.userList = res.Data;
        if (res.Data && res.Data.length != 0) {
          this.config_Pagination.totalItems = res.Data[0].Count;
        }
        // this.toastrService.success(res.message,"Success");

      }
      else {
        this.toastrService.warning(res.message, "Error")
      }


    });

  }
  history = []
  getUserHistory() {
    let url = URL.getUserLoginHistory;
    let payload = {
      CmID: this.userID,
      page: this.AccessHistory_config_Pagination.currentPage - 1
    };
    this.cms.commonPostCall(url, payload).subscribe((res: any) => {
      console.log("the login history", res);
      if (res.Status == 200) {
        this.history = res.Data;
        if (res.Data[0]) {
          // let count = res.Data[0].Count / 10;
          this.AccessHistory_config_Pagination.totalItems = res.Data[0].Count;

        }

      }
      this.Balance();
    });

  }
  pageChangedHistory(p) {
    this.AccessHistory_config_Pagination.currentPage = p;

    this.getUserHistory();
  }
  CoinBalance = 0;



  Balance() {
    this.cms.commonGetCallOtherToken(URL.getBalance + "/" + this.currency.coinId, this.Token)
      .subscribe((resp: any) => {
        console.log("Balance", resp);
        if (resp.Status == 200) {

          this.CoinBalance = resp.Data.balance
        }
        else {

        }
      })
  }



  ngOnDestroy() {
    this.alive = false;
  }
  openTransction(id, type, coinname) {
    //console.log("openTransction:",id,type)


    this.cms.navigateTransction(id, type, coinname)

  }
  toggle(ind, value) {
    console.log(value);
    this.userUpdateStatus(ind, value);
    // if (this.userList[ind].IsActive == true) {
    //   console.log("Active")

    // } else {
    //   console.log("Inactive")
    // }

  }
  userUpdateStatus(idUsr, value) {

    //console.log("Staet", user.IsActive);
    // let payload = {
    //   "CMID": user.CmID,
    //   "IsActive": user.IsActive
    // }
    let payload = {
      "CMID": idUsr,
      "IsActive": value
    }
    console.log("Payloooaddd", payload);
    var url = URL.CHILD_ACTIVE_INACTIVE;
    this.cms.commonPostCall(url, payload).subscribe((res: any) => {
      console.log("Users", res);
      if (res.Status == 200) {
        this.getuserList();
        this.toastrService.success(res.message, "Success");

      }
      else {
        this.toastrService.warning(res.message, "Error")
      }


    });
  }

  resendLink(email) {
    let url = URL.RESEND_LINK;
    let payload = {

      "EMAIL": email

    }
    this.cms.resendVerifLink(url, payload).subscribe(data => {
      let result: any = data;
      console.log(result);
      if (result.Status == 200) {
        this.toastrService.success("A verification link has been sent to the selected user email", "Success");
      } else {
        this.toastrService.warning("Something wen wrong please try again later ", "Error")
      }

    })
  }
}
